import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from './lib/queryClient'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {BrowserRouter} from 'react-router-dom'
import ChannelService from './lib/channelService'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)

ChannelService.boot({
  pluginKey: '165ba9d4-cdca-4a02-b32d-47353dbe7a19'
})
