import React, {useRef} from 'react'
import styled, {StyledComponentProps} from 'styled-components'

export interface ModalProps {
  open: boolean
  onClose: () => void
}

function Modal({open, onClose, children, ...rest}: ModalProps & StyledComponentProps<any, any, any, any>) {
  const modalRef = useRef<HTMLElement>(null)

  if (!open) return null

  return (
    <Wrapper
      ref={modalRef}
      onClick={(event) => {
        if (modalRef.current === event.target) onClose()
      }}
      {...rest}
    >
      <ModalWrapper className="modal_wrapper">
        {children ? <Content>{children}</Content> : <></>}
        <CloseButton onClick={onClose}>닫기</CloseButton>
      </ModalWrapper>
    </Wrapper>
  )
}

export default Modal

const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2000;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);

  transition: padding 0.3s;
`

const ModalWrapper = styled.section`
  min-height: 175px;
  width: 80%;
  position: relative;
  margin: auto;

  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 6px;
`

const CloseButton = styled.button`
  border-radius: 0 0 6px 6px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 56px;
`

const Content = styled.section`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  margin: 16px auto 24px auto;
`
