import Main from '../pages'
import Notice from '../pages/Notice/Notice'
import MainCustomers from '../pages/mainCustomers/MainCustomers'
import Support from '../pages/support/Support'

const main = [
  {index: true, element: <Main />},
  {path: 'notice', element: <Notice />},
  {path: 'customers', element: <MainCustomers />},
  {path: 'support', element: <Support />}
]

export default main
