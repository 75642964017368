import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

interface CardFormProps {
  children: React.ReactNode
  title: string
  more?: string
}

function CardForm({children, title, more}: CardFormProps) {
  return (
    <Wrapper>
      <h3>{title}</h3>
      {more && (
        <LinkBtn to={more}>
          <img src="/assets/icons/right_arrow.svg" alt="arrow" />
        </LinkBtn>
      )}
      {children}
    </Wrapper>
  )
}

export default CardForm

const Wrapper = styled.li`
  position: relative;
  flex: 1;
  min-width: 0;
  border: 1px solid #333;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 80px 40px 40px;
  box-sizing: border-box;

  & > h3 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0 6px;
    font-size: 24px;
    font-weight: 600;
  }

  & > div {
    gap: 30px;
  }
`
const LinkBtn = styled(Link)`
  position: absolute;
  top: 10%;
  right: 40px;
`
