import Title from '../../components/Title'
import SignUpForm from '../../components/forms/SignUpForm'

function SignUp() {
  return (
    <>
      <Title>회원가입</Title>
      <SignUpForm />
    </>
  )
}

export default SignUp
