import FormikField, {IFormikFieldProps} from './FormikField'
import InputButton from '../buttons/InputButton'
import React from 'react'
import {Address, useDaumPostcodePopup} from 'react-daum-postcode'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

function AddressField({name, ...rest}: IFormikFieldProps) {
  const {setFieldValue, values}: FormikContextType<FormikValues> = useFormikContext()
  const DaumPostOpen = useDaumPostcodePopup(process.env.REACT_APP_API_DAUM_SCRIPT_URL)

  const handleComplete = (data: Address) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    setFieldValue(name, fullAddress)
  }

  const handleClickPostSearch = async () => {
    await DaumPostOpen({onComplete: handleComplete})
  }

  return (
    <FormikField
      name={name}
      {...rest}
      value={values[`${name}`]}
      readOnly
      endAdornment={<InputButton title={'검색'} disabled={false} onClick={handleClickPostSearch} />}
    />
  )
}

export default AddressField
