import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

function HeaderList() {
  return (
    <HeaderItems className="HeaderItems">
      <MenuItemWithoutHover to="../customers">주요 고객사</MenuItemWithoutHover>
      <MenuItemWithoutHover to="../notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=notice">
        공지사항
      </MenuItemWithoutHover>
      {/* <MenuItem>
        공지사항
        <Nav className="Nav">
          <MenuItemTitle>
            <NavItem to="../notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=notice">
              공지사항
            </NavItem>
            <NavItem to="../notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=update">
              업데이트 내역
            </NavItem>
          </MenuItemTitle>
        </Nav>
      </MenuItem> */}
      {/* <MenuItem>
        서비스 소개
        <Nav className="Nav">
          <MenuItemTitle>
            <NavItem to="/services/products">서비스 소개</NavItem>
            <NavItem to="/services/payments">요금안내</NavItem>
            <NavItem to="/services/foundation">적용예시</NavItem>
          </MenuItemTitle>
        </Nav>
      </MenuItem> */}
      <MenuItemWithoutHover to="/services/products">서비스 소개</MenuItemWithoutHover>
      <MenuItemWithoutHover to="/services/payments">요금안내</MenuItemWithoutHover>
      <MenuItemWithoutHover to="/services/foundation">적용예시</MenuItemWithoutHover>
      <MenuItemWithoutHover to="../support">고객 지원</MenuItemWithoutHover>
      <HoverBackground className="hoverBackground" />
    </HeaderItems>
  )
}

export default HeaderList

const HeaderItems = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  text-align: center;
  align-items: center;

  .hoverBackground {
    display: none;

    :hover {
      .Nav {
        display: flex;
      }
    }
  }
`

const MenuItemWithoutHover = styled(Link)`
  font-weight: 600;
  height: 100%;
  line-height: 84px;

  :hover {
    color: #f36536;
    box-shadow: 0 -3px ${(p) => p.theme.colors.primary} inset;
    padding-bottom: 2px;
    cursor: pointer;

    .Nav {
      display: flex;
      position: absolute;
      top: 86px;
      z-index: 99;
    }
  }
`

const MenuItem = styled.div`
  font-weight: 600;
  height: 100%;
  line-height: 84px;

  :hover {
    color: #f36536;
    box-shadow: 0 -3px ${(p) => p.theme.colors.primary} inset;
    padding-bottom: 2px;
    cursor: pointer;

    .Nav {
      display: flex;
      position: absolute;
      top: 86px;
      z-index: 99;
    }

    ~ .hoverBackground {
      display: block;
      height: 138px;
      width: 100%;
      position: absolute;
      top: 86px;
      left: 0;
      background-color: white;
      z-index: 90;
      border-top: 2px solid #ededed;
    }`

const MenuItemTitle = styled.div`
  line-height: 17px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  :hover {
    .Nav {
      display: flex;
    }
  }
`

const Nav = styled.nav`
  display: none;
  background-color: white;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;
  border-top: 2px solid #ededed;
`

const NavItem = styled(Link)`
  width: 200px;
`

const HoverBackground = styled.div``
