import {useInsertionEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import useGetAuthQuery from '../hooks/useAuth/useGetAuthQuery'
import useGetUsersQuery from '../hooks/useUsers/useGetUsersQuery'
import Footer from './Footer'

function AuthLayout() {
  const user = useGetAuthQuery()
  const userInfo = useGetUsersQuery()
  const params = useLocation()

  useInsertionEffect(() => {
    if (location.pathname === '/auth/register-profile' && !userInfo?.data?.profile) return
  }, [user])

  return (
    <>
      <Main sign={params.pathname.includes('sign-in')}>
        <Outlet />
      </Main>
      <Footer />
    </>
  )
}

export default AuthLayout

const Main = styled.main<{sign: boolean}>`
  max-width: ${({sign}) => (sign ? '980px' : '400px')};
  width: 100%;
  margin: 160px auto;
`
