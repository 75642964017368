import {FormikProps} from 'formik'
import Button from './Button'
import React from 'react'

interface ISubmitButton {
  title: string
  form: FormikProps<any>
}

function SubmitButton({title, form, ...rest}: ISubmitButton) {
  return (
    <Button
      type="submit"
      style={{marginTop: '8px'}}
      {...rest}
      disabled={!(form.isValid && form.dirty) || form.isSubmitting}
    >
      {title}
    </Button>
  )
}

export default SubmitButton
