import Title from '../../components/Title'
import ResetPasswordForm from '../../components/forms/ResetPasswordForm'

function ResetPassword() {
  return (
    <>
      <Title>비밀번호 재설정</Title>
      <ResetPasswordForm />
    </>
  )
}

export default ResetPassword
