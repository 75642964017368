import React from 'react'
import RegisterProfileForm from '../../components/forms/RegisterProfileForm'

function RegisterProfile() {
  return (
    <>
      <RegisterProfileForm />
    </>
  )
}

export default RegisterProfile
