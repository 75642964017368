import {request} from '@weplanet-web/core'

const PATH = '/selects'

function getComplainTypeItemsWithPlaceManageId(placeManageId: string | null) {
  return request<IGetComplainTypeItemsWithPlaceManageId>({
    url: `${PATH}/complainTypeItems/${placeManageId}`,
    method: 'GET'
  })
}

export {getComplainTypeItemsWithPlaceManageId}
