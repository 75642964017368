import {Link} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'
import Button from '../../components/buttons/Button'

function AuthButtons() {
  return (
    <Wrapper>
      <Link to="/auth/sign-up">
        <RegisterButton>회원 가입</RegisterButton>
      </Link>
      <Link to="/auth/sign-in">
        <LoginButton>로그인</LoginButton>
      </Link>
    </Wrapper>
  )
}

export default AuthButtons

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const LoginButton = styled(Button)`
  padding: 7px 14px;
`

const RegisterButton = styled.span`
  margin-right: 32px;

  white-space: nowrap;
  color: ${(p) => p.theme.colors.primary};

  font-weight: 500;
`
