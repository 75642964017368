import Payments from '../pages/services/Payments'
import Products from '../pages/services/Products'
import Foundation from '../pages/services/Foundation'

const servicesRoutes = [
  {index: true, path: 'payments', element: <Payments />},
  {path: 'products', element: <Products />},
  {path: 'foundation', element: <Foundation />}
]

export default servicesRoutes
