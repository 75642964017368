import React from 'react'
import MobileTable from '../../components/Table/MobileTable'
import {ComplainStatus, ComplainTableDataType} from './Incomplete'
import styled from 'styled-components'
import {useLocation} from 'react-router-dom'
import useGetComplainsWriterQuery from '../../hooks/useComplains/useGetComplainsWriter'
import {dateFormat, timeFormat} from '../../lib/dateFormat'
import {getNumber} from '../../lib/getNumbers'

function Complete() {
  const location = useLocation()
  const complainIdsArray = new URLSearchParams(location.search)
  const {data: complainsWriter} = useGetComplainsWriterQuery(complainIdsArray.get('complainId'))
  const {PhoneNumberMasking} = getNumber()

  const complainTaleData: Array<ComplainTableDataType> = [
    {
      title: '민원 등록 내용',
      content: complainsWriter?.complainTypeItem
        ? complainsWriter.complainTypeItem.complainTypeItemName
        : complainsWriter?.complainEtc
    },
    {
      title: '민원 등록 시간',
      content: complainsWriter
        ? dateFormat(complainsWriter.createdAt) + ' ' + timeFormat(complainsWriter.createdAt)
        : ''
    },
    {
      title: '처리 완료 내용',
      content: complainsWriter?.complainCompletedContent
    },
    {
      title: '처리 완료 시간',
      content: complainsWriter?.complainCompletedAt
        ? dateFormat(complainsWriter.complainCompletedAt) + ' ' + timeFormat(complainsWriter.complainCompletedAt)
        : ''
    },
    {
      title: '처리 완료 사진',
      content: complainsWriter?.complainCompletedPicture ? (
        <img alt={'민원 완료 사진'} width="200" src={complainsWriter?.complainCompletedPicture} />
      ) : (
        ''
      )
    },
    {
      title: '처리결과 통보 방법',
      content: complainsWriter?.notificationType === 'web' ? '웹조회' : '알림톡(문자)'
    },
    {
      title: complainsWriter?.notificationType === 'sms' ? '연락처' : null,
      content:
        complainsWriter?.notificationType === 'sms'
          ? PhoneNumberMasking(complainsWriter?.notificationPhone.toString())
          : null
    }
  ]

  return (
    <>
      <MobileTable rows={complainTaleData} />
      <ComplainStatusArea>
        <ComplainStatus>처리 결과는 한달동안 확인 가능합니다.</ComplainStatus>
      </ComplainStatusArea>
    </>
  )
}

export default Complete

export const ComplainStatusArea = styled.div`
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-items: center;
  flex-direction: column;
  gap: 24px;
`

const NotiArea = styled.div`
  p {
    line-height: 130%;
  }
`
