import styled from 'styled-components'
import {RoundButton} from '../../components/buttons/RoundButton'
import {useLocation, useNavigate} from 'react-router-dom'
import useGetComplainPlaceCheck from '../../hooks/useComplains/useGetComplainPlaceCheck'
import Modal from '../../components/Modal'
import {useCallback, useState} from 'react'

function Complain() {
  const navigate = useNavigate()
  const location = useLocation()
  const complainArea = new URLSearchParams(location.search)
  const id = complainArea.get('placeManageId') || complainArea.get('selectId')

  const {data: complainPlace} = useGetComplainPlaceCheck(id)
  const [modalOpen, setModalOpen] = useState(false)
  const workTime = complainPlace?.business?.workTime

  const handleClickComplainButton = () => {
    complainPlace?.isActivate && complainPlace?.isComplain
      ? navigate(`/complain/complaint-request?placeManageId=${id}`)
      : window.alert('민원 등록을 할 수 없는 시설입니다.')
  }

  const handleComplainCheckTimeModal = useCallback(() => {
    setModalOpen((prevState) => !prevState)
  }, [])

  const ComplainSchedule = ({label, time}: {label: string; time: {startTime: string; endTime: string} | null}) => {
    return (
      <p>
        {label} &nbsp; {time ? `${time.startTime} ~ ${time.endTime}` : '-'}
      </p>
    )
  }

  return (
    <Wrapper>
      <div role="img" aria-label="complainPlaceImage">
        <StyledImg src={complainPlace?.areaPicture || complainPlace?.image} />
      </div>
      {complainPlace?.isActivate && complainPlace?.isComplain && (
        <ButtonsWrapper>
          <WhiteRoundButton onClick={() => setModalOpen(true)}>민원 처리 가능 시간</WhiteRoundButton>
          <Modal title={'민원 처리 가능 시간'} open={modalOpen} onClose={handleComplainCheckTimeModal}>
            <ComplainSchedule label={'월요일'} time={workTime ? workTime.monday : null} />
            <ComplainSchedule label={'화요일'} time={workTime ? workTime.tuesday : null} />
            <ComplainSchedule label={'수요일'} time={workTime ? workTime.wednesday : null} />
            <ComplainSchedule label={'목요일'} time={workTime ? workTime.thursday : null} />
            <ComplainSchedule label={'금요일'} time={workTime ? workTime.friday : null} />
            <ComplainSchedule label={'토요일'} time={workTime ? workTime.saturday : null} />
            <ComplainSchedule label={'일요일'} time={workTime ? workTime.sunday : null} />
          </Modal>
          <RoundButton onClick={handleClickComplainButton}>민원 등록</RoundButton>
        </ButtonsWrapper>
      )}
    </Wrapper>
  )
}

export default Complain

const Wrapper = styled.section`
  display: flex;

  flex-direction: column;
  align-items: center;
`

const StyledImg = styled.img`
  width: 100%;
  max-height: 240px;
  border-radius: 4px;
  margin-bottom: 97px;
`

const WhiteRoundButton = styled(RoundButton)`
  background-color: white;
  color: #f36536;
  border: 1px solid #f36536;
`

const ButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`
