import {atom} from 'recoil'

interface INotificationType {
  notificationType: 'web' | 'sms' | 'all'
  id: number
}

export const notificationType = atom<INotificationType>({
  key: 'notificationType',
  default: {
    notificationType: 'web',
    id: 0
  }
})
