import FormikField from '../fields/FormikField'
import React from 'react'
import FileField from '../fields/FileField'
import AddressField from '../fields/AddressField'

function CompanyInfoForm() {
  return (
    <>
      <FormikField label="회사명" name="companyName" placeholder="회사명을 입력해주세요." />
      <FormikField label="대표자명" name="ceoName" placeholder="대표자명을 입력해주세요." />
      <FormikField label="사업자번호" name="businessNo" placeholder="사업자번호를 입력해주세요." />
      <FileField label="사업자등록증" name="businessLicense" placeholder="사업자 등록증을 업로드해주세요" />
      <FormikField label="업태" name="businessType" placeholder="업태를 입력해주세요." />
      <FormikField label="종목" name="businessCategory" placeholder="종목을 입력해주세요." />
      <AddressField label="회사 주소" name="address" className="narrow_field" placeholder="주소를 검색해주세요." />
      <FormikField name="detailAddress" placeholder="상세주소를 입력해주세요." />
      <FormikField label="세금계산서용 이메일" name="taxBillEmail" placeholder="이메일 주소를 입력해주세요." />
    </>
  )
}

export default CompanyInfoForm
