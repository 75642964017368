import FormikField from '../fields/FormikField'
import React from 'react'
import VerificationField from '../fields/VerificationField'
import InputButton from '../buttons/InputButton'
import useVerification from '../../hooks/useVerification'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import VerificationsType from '../../@types/enums/VerificationsType'

function ManagerInfoForm() {
  const {handleVerify} = useVerification()
  const {values}: FormikContextType<FormikValues> = useFormikContext()
  return (
    <>
      <FormikField label="담당자명" name="managerName" className="field" placeholder="담당자명" />
      <FormikField label="담당자 이메일" name="managerEmail" className="field" placeholder="이메일 주소" />
      <FormikField
        label="담당자 휴대폰번호"
        name="managerPhone"
        className="field"
        placeholder="휴대폰 번호"
        endAdornment={
          <InputButton
            title={'인증'}
            disabled={!values.managerPhone}
            onClick={async () => {
              await handleVerify(values['managerPhone'], VerificationsType.REGISTER)
            }}
          />
        }
      />
      <VerificationField buttonName={'확인'} name={'code'} />
    </>
  )
}

export default ManagerInfoForm
