import React from 'react'
import styled from 'styled-components'
import TableHead from './TableHead'
import TableBody from './TableBody'
import {HeadCellType} from '../../pages/Notice/Notice'

function Table({headCells, rows}: {headCells: Array<HeadCellType>; rows: any}) {
  return (
    <TableArea>
      <TableHead headCells={headCells} />
      <TableBody rows={rows} />
    </TableArea>
  )
}

export default Table

const TableArea = styled.table`
  width: 100%;
`
