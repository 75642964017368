import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

function Logo() {
  return (
    <Link to={'/'}>
      <Image className="logo" src="/assets/images/HELPME365_Logo.svg" />
    </Link>
  )
}

export default Logo

const Image = styled.img`
  width: 185px;
  height: 36px;
`
