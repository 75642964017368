import styled from 'styled-components'
import useGetUserStatusesQuery from '../../hooks/useUserStatuses/useGetUserStatusesQuery'
import NoticeLayout from '../../layouts/NoticeLayout'

function UserStatuses() {
  const {data: userStatuses, isLoading, isError} = useGetUserStatusesQuery()

  if (isLoading) return <div></div>

  if (isError) return <div></div>

  return (
    <NoticeLayout className="notice" title="시설 현황">
      <Wrapper>
        <CustomersArea>
          <h3>{userStatuses.customerCount.toLocaleString()}사업장</h3>
          <p>/</p>
          <h3>{userStatuses.placeCount.toLocaleString()}시설</h3>
          <p>/</p>
          <h3>{userStatuses.operatorCount.toLocaleString()}명</h3>
          <span>(현장담당자)</span>
        </CustomersArea>
      </Wrapper>
    </NoticeLayout>
  )
}

export default UserStatuses

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const CustomersArea = styled.div`
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  align-items: center;
  & > p {
    margin: 0 40px;
  }
  & > span {
    font-size: 30px;
    font-weight: 500;
    color: #8e8e93;
  }
`
