import React, {useCallback, useEffect} from 'react'
import {deleteAuth, getAuth} from './api/auth/index'
import {useSetRecoilState} from 'recoil'
import {userInfoState} from './stores/userInfo'
import {usePage} from './hooks/usePage'
import {toast} from 'react-toastify'

function UserProvider({children}: {children: JSX.Element | JSX.Element[]}) {
  const setUserInfo = useSetRecoilState(userInfoState)
  const {handleToDashboard} = usePage()

  const handleFetchUser = useCallback(async () => {
    if (['/complain', '/auth', '/'].includes(location.pathname)) {
      return

      try {
        const res: any = await getAuth()
        if (res) {
          setUserInfo({...res})
          handleToDashboard()
        }
      } catch (e: any) {
        if (e.status === 401) {
          if (location.pathname !== '/auth/sign-in') {
            location.replace('/auth/sign-in')
          }
        } else {
          console.log(JSON.parse(JSON.stringify(e)))
        }
      }
    }
  }, [])

  useEffect(() => {
    handleFetchUser()
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await deleteAuth()
      toast.success('로그아웃 성공')
    } catch (e: any) {
      alert(e.message || '로그아웃 실패')
    }
  }, [])
  return <>{children}</>
}

export default UserProvider
