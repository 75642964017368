import {atom} from 'recoil'

export interface IUserInfo {
  setting: {
    agreeMarketing: boolean
  }
  id: number
  createdAt: string
  updatedAt: string
}

export const userInfoState = atom<IUserInfo>({
  key: 'userInfo',
  default: {
    setting: {
      agreeMarketing: false
    },
    id: 0,
    createdAt: '',
    updatedAt: ''
  }
})
