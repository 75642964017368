import Icon from '../Icon'
import React from 'react'
import styled from 'styled-components'
import {getPreviewSrc} from '@weplanet-web/core'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

interface ImgButtonProps {
  count: number
  name: string
  setPreview: (value: ((prevState: string) => string) | string) => void
}

function ImgButton({name, count, setPreview}: ImgButtonProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const {setFieldValue}: FormikContextType<FormikValues> = useFormikContext()
  const handleClickButton = () => {
    inputRef.current?.click()
  }

  const handleChangeSetPreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (files && files[0]) {
      setPreview(getPreviewSrc(files[0]))
      setFieldValue(name, files)
    }
  }

  return (
    <>
      <input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={handleChangeSetPreview} />
      <ImgEnrollButton type={'button'} onClick={handleClickButton}>
        <Icon name={'camera'} width={'24px'} height={'20px'} />
        <span>{count}/1</span>
      </ImgEnrollButton>
    </>
  )
}

export default ImgButton

const ImgEnrollButton = styled.button`
  width: 72px;
  height: 72px;
  color: #999999;

  background-color: white;
  padding: 14px 24px 12px;

  border: 1px solid #e4e4e4;

  display: flex;
  flex-direction: column;

  gap: 6px;
`
