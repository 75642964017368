import styled from 'styled-components'

function Payments() {
  return (
    <Wrapper>
      <Title>요금안내</Title>
      <img src="/assets/images/pay01.png" alt="요금안내" />
      <ServiceWrapper>
        <Title>서비스 비용(VAT포함)</Title>
        <img src="/assets/images/pay02.png" alt="서비스 비용" />
      </ServiceWrapper>
    </Wrapper>
  )
}

export default Payments

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  width: 100%;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin-top: 64px;
`

const ServiceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  align-items: center;
  gap: 65px;
  padding-bottom: 120px;
`

const ServicesImageArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`

const ServicesPriceImageArea = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 620px;
`
