import {Form, Formik} from 'formik'
import * as yup from 'yup'
import FormikField from '../fields/FormikField'
import PasswordField from '../fields/PasswordField'
import React, {useCallback, useState} from 'react'
import InputButton from '../buttons/InputButton'
import TermsForm from './TermsForm'
import SubmitButton from '../buttons/SubmitButton'
import {useNavigate} from 'react-router-dom'
import {postAuthRegister} from '../../api/auth'
import {toast} from 'react-toastify'
import useAccountIdCheck from '../../hooks/useAccountIdCheck'
import {FormikHelpers} from 'formik/dist/types'

interface IInitialFormValues {
  accountId: string
  password: string
  confirmPassword: string
  terms: {
    over14YearsOld: boolean
    termsOfServiceConsent: boolean
    privacyPolicyConsent: boolean
    PersonalInfoCollectionConsent: boolean
    marketing: boolean
  }
}

const initialFormValues = {
  accountId: '',
  password: '',
  confirmPassword: '',
  terms: {
    over14YearsOld: false,
    termsOfServiceConsent: false,
    privacyPolicyConsent: false,
    PersonalInfoCollectionConsent: false,
    marketing: false
  }
}

const validationSchema = yup.object().shape({
  accountId: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, '*영문, 숫자를 이용해 6~12자로 입력해주세요.')
    .required('*영문, 숫자를 이용해 6~12자로 입력해주세요.')
    .min(6, '*영문, 숫자를 이용해 6~12자로 입력해주세요.')
    .max(12, '*영문, 숫자를 이용해 6~12자로 입력해주세요.'),
  password: yup
    .string()
    .matches(
      /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,}$/,
      '*영문, 숫자, 특수기호 중 2가지 이상을 조합해 8자리이상으로 입력해주세요.'
    )
    .required('*영문, 숫자, 특수기호 중 2가지 이상을 조합해 8자리이상으로 입력해주세요.'),
  confirmPassword: yup
    .string()
    .required('*비밀번호를 다시한번 입력해주세요')
    .oneOf([yup.ref('password'), null], '*비밀번호를 일치시켜 주세요.'),
  terms: yup.object().shape({
    over14YearsOld: yup.boolean().oneOf([true], '*필수약관에 동의해주세요.'),
    termsOfServiceConsent: yup.boolean().oneOf([true], '*필수약관에 동의해주세요.'),
    privacyPolicyConsent: yup.boolean().oneOf([true], '*필수약관에 동의해주세요.'),
    PersonalInfoCollectionConsent: yup.boolean().oneOf([true], '*필수약관에 동의해주세요.')
  })
})

function SignUpForm() {
  const navigate = useNavigate()
  const {handleAccountIdCheck} = useAccountIdCheck()
  const [accountIdValidation, setAccountIdValidation] = useState(false)
  const [accountIdValue, setAccountIdValue] = useState('')

  const handleSubmit = useCallback(
    async (values: IInitialFormValues, {setSubmitting}: FormikHelpers<IInitialFormValues>) => {
      const {accountId, password, terms} = values

      if (!accountIdValidation) {
        return toast.error('아이디 중복검사를 해주세요.')
      }
      try {
        await postAuthRegister({accountId, password, agreeMarketing: terms.marketing})
        await navigate('/auth/register-profile')
      } catch (err: any) {
        switch (err.status) {
          case 409:
            toast.error('아이디 중복검사를 해주세요.')
            break
          default:
            toast.error('잠시후 다시 시도해주세요.')
        }
      }
      setSubmitting(false)
    },
    [accountIdValidation]
  )

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(form) => (
        <Form>
          <FormikField
            name={'accountId'}
            label={'아이디'}
            onChange={(e) => {
              form.setFieldValue('accountId', e.target.value)
              setAccountIdValue(e.target.value)
            }}
            endAdornment={
              <InputButton
                title="중복확인"
                disabled={!accountIdValue}
                onClick={async () => {
                  await handleAccountIdCheck(form.values['accountId'])
                  setAccountIdValidation(true)
                }}
              />
            }
          />
          <PasswordField name={'password'} label={'비밀번호'} />
          <PasswordField name={'confirmPassword'} label={'비밀번호 확인'} />
          <TermsForm />
          <SubmitButton title="가입 완료" form={form} />
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
