import SubmitButton from '../buttons/SubmitButton'
import React from 'react'
import FormikField from '../fields/FormikField'
import InputButton from '../buttons/InputButton'
import VerificationsType from '../../@types/enums/VerificationsType'
import VerificationField from '../fields/VerificationField'
import useVerification from '../../hooks/useVerification'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

function AccountVerificationForm() {
  const {handleVerify} = useVerification()

  const form: FormikContextType<FormikValues> = useFormikContext()

  return (
    <>
      <FormikField name="accountId" placeholder="아이디" />
      <FormikField
        name="phone"
        placeholder="휴대폰 번호"
        endAdornment={
          <InputButton
            title={'인증'}
            disabled={!form.values.phone}
            onClick={async () => {
              await handleVerify(form.values['phone'], VerificationsType.RESET_PASSWORD)
            }}
          />
        }
      />
      <VerificationField name="code" buttonName="확인" />
      <SubmitButton title="확인" form={form} />
    </>
  )
}

export default AccountVerificationForm
