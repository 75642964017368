import React from 'react'
import {Outlet} from 'react-router-dom'
import Footer from './Footer'
import styled from 'styled-components'

function Layout() {
  return (
    <>
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  )
}

export default Layout

const Main = styled.main`
  flex: 1;
  padding: calc((100vh - 643px) / 2) calc((100% - 1080px) / 2);
`
