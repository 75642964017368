export const getNumber = () => {
  const cardNumberWithHyphen = (cardNumber: string) => {
    return cardNumber?.replace(/ /g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4')
  }

  const cardNumberMasking = (cardNumber: string) => {
    return cardNumber?.replace(/(\d{4})-(\d{4})-(\d{4})-(\d{4})/gi, '$1-****-****-****')
  }

  const priceWithComma = (price: number | string) => {
    return price?.toLocaleString()
  }

  const numberInPhone = (phone: string) => {
    return phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
  }

  const PhoneNumberMasking = (phone: string) => {
    const maskingStr = phone.toString().replace(phone, phone.toString().replace(/(\d{3})(\d{4})(\d{4})/gi, '$1****$3'))
    return maskingStr
  }

  return {cardNumberWithHyphen, cardNumberMasking, priceWithComma, numberInPhone, PhoneNumberMasking}
}
