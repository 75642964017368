import React from 'react'
import {ThemeProvider} from 'styled-components'
import theme from './styles/theme'
import {ApiFetchProvider} from '@weplanet-web/core'
import GlobalStyles from './styles/globalStyles'
import {RecoilRoot} from 'recoil'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserProvider from './userProvider'
import Routes from './routes'

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        limit={5}
        pauseOnHover={false}
      />
      <GlobalStyles />
      <ThemeProvider theme={{...theme}}>
        <RecoilRoot>
          <ApiFetchProvider config={{baseURL: process.env.REACT_APP_API_BASE_URL || ''}}>
            <UserProvider>
              <Routes />
            </UserProvider>
          </ApiFetchProvider>
        </RecoilRoot>
      </ThemeProvider>
    </>
  )
}

export default App
