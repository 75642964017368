import {useQuery} from '@tanstack/react-query'
import {queryKeys} from '../../constants/queryKey'
import {getComplainPlaceCheck, getPointPlaceCheck} from '../../api/complains'

function useGetComplainPlaceCheck(placeManageId: string | null) {
  return useQuery<any>(
    [queryKeys.complainPlaceCheck],
    async () => {
      const [complainResult, pointResult] = await Promise.allSettled([
        getComplainPlaceCheck(placeManageId),
        getPointPlaceCheck({start: 0, perPage: 100, selectId: placeManageId})
      ])

      if (complainResult.status === 'fulfilled' && complainResult.value) {
        return (complainResult.value as any)?.['data']?.[0] ?? complainResult.value
      }

      if (pointResult.status === 'fulfilled' && pointResult.value) {
        return (pointResult.value as any)?.['data']?.[0]
      }

      return false
    },
    {}
  )
}

export default useGetComplainPlaceCheck
