import Title from '../../components/Title'
import FindPasswordForm from '../../components/forms/FindPasswordForm'
import {useSearchParams} from 'react-router-dom'
import {useMemo} from 'react'

function FindPassword() {
  const [searchParams] = useSearchParams()
  const step = searchParams.get('step') ?? 'accountVerification'

  const title = useMemo(() => {
    switch (step) {
      case 'accountVerification':
        return '비밀번호 찾기'
        break
      case 'resetPassword':
        return '비밀번호 재설정'
    }
  }, [step])

  return (
    <>
      <Title>{title}</Title>
      <FindPasswordForm step={step} />
    </>
  )
}

export default FindPassword
