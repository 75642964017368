import {useRecoilState} from 'recoil'

import {useCallback} from 'react'
import {postVerifications, postVerificationsConfirm} from '../api/verifications'
import {verificationState} from '../stores/verification'
import VerificationsType from '../@types/enums/VerificationsType'
import {toast} from 'react-toastify'

function useVerification() {
  const [verify, setVerify] = useRecoilState(verificationState)

  const handleVerify = useCallback(async (phone: string, type: VerificationsType, placeManageId?: number) => {
    try {
      const data = await postVerifications({phone, type, placeManageId})
      setVerify({
        ...verify,
        verify: true,
        showVerificationField: true,
        expireAt: data.expireAt,
        codeToken: data.codeToken
      })
      if (data.code) {
        localStorage.setItem('code', data.code)
      }
      toast.success('인증코드를 확인해주세요.')
    } catch (err: any) {
      switch (err.status) {
        case 400:
        case 404:
          toast.warn('올바른 휴대폰 번호를 입력해주세요.')
          break
        case 409:
          toast.warn('이미 사용중인 휴대폰번호입니다.')
          break
        default:
          toast.warn('잠시후 다시 시도해주세요.')
          break
      }
    }
  }, [])

  const handleCodeVerify = useCallback(
    async (code: string) => {
      try {
        const data = await postVerificationsConfirm({code, codeToken: verify.codeToken})
        setVerify({...verify, verify: false, phoneToken: data.codeToken})

        toast.success('휴대폰 인증에 성공했어요.')
      } catch (err: any) {
        switch (err.status) {
          case 400:
            toast.error('인증번호를 입력해주세요.')
            break
          case 401:
            toast.error('올바른 인증번호를 입력해주세요.')
            break
          case 404:
          case 409:
            toast.warn(err.data.message)
            break
          default:
            toast.warn('잠시후 다시 시도해주세요.')
            break
        }
      }
    },
    [verify]
  )

  return {handleVerify, handleCodeVerify}
}

export default useVerification
