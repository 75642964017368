import styled from 'styled-components'
import CardForm from './CardForm'

function CustomerServices() {
  return (
    <CardForm title={'고객지원'}>
      <ServicesIndex>
        <Index>고객지원 연락처</Index>
        <Content className="PhoneNumber">070-4366-0555</Content>
      </ServicesIndex>
      <ServicesIndex>
        <Index>운영시간</Index>
        <Content>평일 오전 10시 - 오후 05시</Content>
      </ServicesIndex>
      <ServicesIndex>
        <Index>문의메일</Index>
        <Content>support@helpme365.kr</Content>
      </ServicesIndex>
    </CardForm>
  )
}

export default CustomerServices

const ServicesIndex = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

const Index = styled.p`
  font-size: 16px;
  font-weight: 600;
`

const Content = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #999999;

  .PhoneNumber {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: black;
  }
`
