import {useMemo, useState} from 'react'
import Icon from '../Icon'
import FormikField, {IFormikFieldProps} from './FormikField'

function PasswordField(props: IFormikFieldProps) {
  const [view, setView] = useState(false)

  const handlePasswordView = useMemo(
    () => (
      <button type="button" onClick={() => setView(!view)} style={{background: 'white'}}>
        <Icon name={`eye${!view ? '' : '_blind'}`} width={'24px'} height={'24px'} />
      </button>
    ),
    [view]
  )

  return (
    <FormikField type={view ? 'string' : 'password'} autoComplete="on" endAdornment={handlePasswordView} {...props} />
  )
}

export default PasswordField
