import React from 'react'
import SignIn from '../pages/auth/SignIn'
import SignUp from '../pages/auth/SignUp'
import FindPassword from '../pages/auth/FindPassword'
import ResetPassword from '../pages/auth/ResetPassword'
import RegisterProfile from '../pages/auth/RegisterProfile'

const authRoutes = [
  {index: true, path: 'sign-in', element: <SignIn />},
  {path: 'sign-up', element: <SignUp />},
  {path: 'find-password', element: <FindPassword />},
  {path: 'reset-password', element: <ResetPassword />},
  {path: 'register-profile', element: <RegisterProfile />}
]

export default authRoutes
