import {Outlet, useLocation} from 'react-router-dom'
import ComplainHeader from './Header/ComplainHeader'
import styled from 'styled-components'
import useGetComplainPlaceCheck from '../hooks/useComplains/useGetComplainPlaceCheck'
import {useSetRecoilState} from 'recoil'
import {notificationType} from '../stores/notificationType'
import {useMemo} from 'react'

function ComplainLayout() {
  const setNotificationType = useSetRecoilState(notificationType)
  const location = useLocation()
  const complainArea = new URLSearchParams(location.search)
  const id = complainArea.get('placeManageId') || complainArea.get('selectId')
  const {data: complainPlace, isLoading, isError} = useGetComplainPlaceCheck(id)

  const isQrCodePCK = complainPlace?.qrcodeType === 'PCK' || complainPlace?.type === 'PCK'

  if (complainPlace?.business) {
    setNotificationType({
      notificationType: complainPlace.business?.notificationType,
      id: complainPlace.id
    })
  }
  if (isError)
    return (
      <Error>
        <p>등록되지 않은 시설입니다.</p>
        <p>시설리스트를 확인해주세요.</p>
      </Error>
    )

  if (isLoading) return <></>
  if (!complainPlace) {
    return (
      <Error>
        <p>등록되지 않은 시설입니다. </p>
        <p>시설리스트를 확인해주세요.</p>
      </Error>
    )
  }

  const bannerImage = complainPlace?.business?.isCustomerImage ? complainPlace?.business?.customerImage : ''
  const businessName = complainPlace?.business?.businessName || complainPlace?.businessName || ''
  const buildingName = complainPlace?.building?.buildingName || ''
  const placeName = complainPlace?.placeName || ''
  const attendancePoint = complainPlace?.attendancePoint || ''

  return (
    <>
      <ComplainHeader customerImage={bannerImage} />
      <Main>
        <Place>
          <p>{businessName}</p>
          {!isQrCodePCK && <p className="location_detail">{buildingName}</p>}
          {!isQrCodePCK && <p className="location_detail">{placeName}</p>}
          <p className="location_detail">{attendancePoint}</p>
        </Place>
        <Outlet />
        {isQrCodePCK && (
          <p style={{marginTop: '30px'}} className="pck">
            도와줘365 앱으로 출/퇴근을 인증해보세요.
          </p>
        )}
      </Main>
    </>
  )
}

export default ComplainLayout

const Main = styled.main`
  box-sizing: border-box;
  padding: 61px 24px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .pck {
    text-align: center;
  }
`

const Place = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0 26px;

  font-size: 20px;

  .location_detail {
    padding-top: 8px;
    color: #757575;
    font-size: 16px;
  }
`

const Error = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 700;
`
