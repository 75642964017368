import styled from 'styled-components'
import {HTMLAttributes} from 'react'

interface IconProps extends HTMLAttributes<HTMLImageElement> {
  name?: string
  width: string
  height: string
  alt?: string
}

function Icon({name, alt, ...rest}: IconProps) {
  return <Img aria-label="icon" src={`/assets/icons/${name}.svg`} alt={alt || name} {...rest} />
}

export default Icon

const Img = styled.img`
  cursor: pointer;
`
