import React, {ReactNode} from 'react'
import MobileTable from '../../components/Table/MobileTable'
import styled from 'styled-components'
import {usePage} from '../../hooks/usePage'
import {ComplainStatusArea} from './Complete'
import {useLocation} from 'react-router-dom'
import useGetComplainsWriterQuery from '../../hooks/useComplains/useGetComplainsWriter'
import {dateFormat, timeFormat} from '../../lib/dateFormat'
import {toast} from 'react-toastify'
import {getNumber} from '../../lib/getNumbers'

export interface ComplainTableDataType {
  title: string | null
  content?: string | ReactNode | null
}

function Incomplete() {
  const location = useLocation()
  const complainString = new URLSearchParams(location.search)
  const {handleToComplainResult} = usePage()
  const {data: complainsWriter, refetch} = useGetComplainsWriterQuery(complainString.get('complainId'))
  const {PhoneNumberMasking} = getNumber()

  const complainTaleData: Array<ComplainTableDataType> = [
    {
      title: '민원 등록 내용',
      content: complainsWriter?.complainTypeItem
        ? complainsWriter.complainTypeItem.complainTypeItemName
        : complainsWriter?.complainEtc
    },
    {
      title: '민원 등록 사진',
      content: complainsWriter?.complainPicture ? (
        <img alt={'민원 사진'} width="200" src={complainsWriter?.complainPicture} />
      ) : (
        ''
      )
    },
    {
      title: '처리결과 통보 방법',
      content: complainsWriter?.notificationType === 'web' ? '웹조회' : '알림톡(문자)'
    },
    {
      title: complainsWriter?.notificationType === 'sms' ? '연락처' : null,
      content:
        complainsWriter?.notificationType === 'sms'
          ? PhoneNumberMasking(complainsWriter?.notificationPhone.toString())
          : null
    },
    {
      title: '민원 등록 시간',
      content: complainsWriter
        ? dateFormat(complainsWriter.createdAt) + ' ' + timeFormat(complainsWriter.createdAt)
        : ''
    }
  ]

  window.history.forward()

  function blockTouchStart(event: any) {
    if (event.pageX > 20) return
    event.preventDefault()
  }

  window.addEventListener('touchstart', blockTouchStart)

  const handleCheckComplainStatus = async () => {
    await refetch()
    complainsWriter?.complainCompletedAt
      ? handleToComplainResult(complainString.get('placeManageId') || '', complainString.get('complainId') || '')
      : toast.error('아직 민원이 처리되지 않았습니다.')
  }

  return (
    <>
      <MobileTable rows={complainTaleData} />
      <ComplainArea>
        <ComplainStatus>
          {complainsWriter?.notificationType === 'sms' ? (
            <NotiArea>
              <p>민원이 정상적으로 등록되었습니다.</p> <p>처리 결과는 알림톡(문자)로 통보해드리겠습니다.</p>
            </NotiArea>
          ) : (
            '민원을 처리중입니다.'
          )}
        </ComplainStatus>
        {complainsWriter?.notificationType === 'web' && (
          <button onClick={handleCheckComplainStatus}>처리결과 확인</button>
        )}
      </ComplainArea>
    </>
  )
}

export default Incomplete

const ComplainArea = styled(ComplainStatusArea)`
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-items: center;
  flex-direction: column;
  gap: 24px;

  button {
    height: 48px;
    border-radius: 54px;
    font-weight: 500;
  }
`

export const ComplainStatus = styled.span`
  color: #f31515;
`

const NotiArea = styled.div`
  p {
    line-height: 130%;
  }
`
