import {useNavigate} from 'react-router-dom'

export function usePage() {
  const navigate = useNavigate()

  const handleToVOC = () => {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/manage/complaint/all?page=1&pageSize=10`)
  }

  const handleToDashboard = () => {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/manage/dashboard`)
  }

  const handleToComplainResult = (placeManageId: string, complainId: string) => {
    navigate(`../complete?placeManageId=${placeManageId}&complainId=${complainId}`)
  }

  return {handleToDashboard, handleToComplainResult, handleToVOC}
}
