import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import React, {HTMLProps, PropsWithChildren} from 'react'
import styled from 'styled-components'

interface ICheckboxProps extends PropsWithChildren<HTMLProps<HTMLInputElement>> {
  consent?: 'essential' | 'optional'
  className?: string
  children?: PropsWithChildren<any>
  name: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

function Checkbox({consent, name, children, onChange, className, ...rest}: ICheckboxProps) {
  const form: FormikContextType<FormikValues> = useFormikContext()
  return (
    <Wrapper className={className}>
      <input
        type="checkbox"
        id={name || rest.id}
        onChange={(event) => {
          if (onChange) onChange(event)
          else {
            form.setFieldValue(name, event.target.checked)
          }
        }}
        {...rest}
      />
      <label htmlFor={name || rest.id}>
        <span>
          {children}
          <Consent consent={consent}> {consent ? (consent === 'essential' ? '(필수)' : '(선택)') : ''}</Consent>
        </span>
      </label>
    </Wrapper>
  )
}

export default Checkbox

const Wrapper = styled.div`
  height: 20px;
  display: flex;
  margin: 16px 0px;

  input[type='checkbox'] {
    margin: 0;
    display: none;

    & + label:before {
      background-image: url('/assets/icons/checkbox_unclicked.svg');
      background-size: contain;
    }

    &:checked + label {
      &:before {
        background-image: url('/assets/icons/checkbox_clicked.svg');
        background-size: contain;
      }
    }
  }

  label {
    flex: 1;
    cursor: pointer;
    color: #333333;
    font-weight: 400;
    display: flex;
    align-items: center;

    &:before {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      content: 'checkbox';
      color: transparent;
    }
  }
`

const Consent = styled.span<{consent?: string}>`
  color: ${(props) => (props.consent === 'essential' ? '#F36536' : '#D7D5D5')};
`
