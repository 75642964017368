import {DefaultTheme} from 'styled-components'

const theme: DefaultTheme = {
  border: 'solid 1px rgba(219, 219, 219, 100)',

  colors: {
    primary: '#F36536', //버튼, on
    secondary: '#D7D5D5', //버튼, off
    error: '#E70000',
    error_secondary: '#ff3b30',
    success: '#48BB78',
    success_secondary: '#04c99d',
    success_tertiary: '#34c759',
    link: '#1E90FF',

    onText: '#F36536', //text 선택
    offText: '#CFCFCF', //text 선택 해제
    footer: '#363636',

    BASIC_1: '#EB603E',
    BASIC_2: '#2C99F0',
    BASIC_3: '#159786'
  }
}

export default theme
