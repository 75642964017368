import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Table from '../../components/Table/Table'
import qs from 'query-string'
import useGetNoticesQuery from '../../hooks/useNotices/useGetNoticesQuery'
import {dateFormat} from '../../lib/dateFormat'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'

export interface HeadCellType {
  id: number | string
  label: string
  width?: string
}

export interface TabContent {
  notice: boolean
}

export const HEAD_CELLS: Array<HeadCellType> = [
  {id: 'id', label: '분류', width: '100px'},
  {id: 'title', label: '제목', width: '450px'},
  {id: 'nickname', label: '작성자', width: '110px'},
  {id: 'createdAt', label: '작성일', width: '200px'}
]

function Notice() {
  const apiParams: IGetNotices & any = qs.parse(location.search)
  const {data: notices} = useGetNoticesQuery(apiParams)
  const navigate = useNavigate()
  const [changedUrl, setChangedUrl] = useState<string>('')

  const noticeList =
    notices &&
    notices.data &&
    notices.data.map((notice: INotice) => ({
      id: notice.id,
      title: notice.title,
      nickname: notice.administrator.nickname,
      createdAt: dateFormat(notice.createdAt)
    }))

  const handlePageClick = (e: any) => {
    const changedUrlObject = {...apiParams, start: (e.selected + 1) * 10 - 10}
    const changedUrl = new URLSearchParams(changedUrlObject).toString()
    navigate('/notices/all?' + changedUrl)
  }

  return (
    <Wrapper>
      <Title>공지사항</Title>
      {/* <TabArea>
        <TabButton
          notice={apiParams.noticeType === 'notice'}
          onClick={() => {
            navigate('/notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=notice')
          }}
        >
          공지사항
        </TabButton>
        <TabButton
          notice={apiParams.noticeType === 'update'}
          onClick={() => {
            navigate('/notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=update')
          }}
        >
          업데이트 내역
        </TabButton>
      </TabArea> */}
      <Table headCells={HEAD_CELLS} rows={noticeList} />
      <PaginationArea
        pageCount={notices && notices.data ? Math.ceil(notices.total / 10) : 0}
        pageRangeDisplayed={10}
        nextLabel=">"
        breakLabel="..."
        previousLabel="<"
        onPageChange={(e) => handlePageClick(e)}
        activeClassName={'currentPage'}
      />
    </Wrapper>
  )
}

export default Notice

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 0 260px;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  margin-top: 64px;

  color: #333333;
`

const TabArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
  color: #c4c4c4;
`

const TabButton = styled.span<TabContent>`
  color: ${(props) => (props.notice ? '#F36536' : '#c4c4c4')};
  cursor: pointer;
`

export const PaginationArea = styled(ReactPaginate)`
  display: flex;
  flex-direction: row;
  gap: 5px;

  .currentPage {
    a {
      color: black;
    }
  }

  li {
    padding: 5px 5px;
    cursor: pointer;

    a {
      color: #979797;

      :hover {
        color: black;
      }
    }
  }
`
