import {useQuery} from '@tanstack/react-query'
import {useSetRecoilState} from 'recoil'
import {queryKeys} from '../../constants/queryKey'
import {getAuth} from '../../api/auth'
import {userInfoState} from '../../stores/userInfo'

function useGetMajorCustomersQuery() {
  const setUser = useSetRecoilState(userInfoState)

  return useQuery<any>(
    [queryKeys.auth],
    async () => {
      const user = await getAuth()
      setUser(user)
      return user
    },
    {}
  )
}

export default useGetMajorCustomersQuery
