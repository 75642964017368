import React from 'react'
import styled from 'styled-components'
import Logo from '../../components/Logo'
import HeaderList from './HeaderList'
import AuthButtons from './AuthButtons'

function Index() {
  return (
    <Wrapper>
      <div style={{display: 'flex'}}>
        <div style={{padding: '23.5px 0'}}>
          <Logo />
        </div>
        <HeaderList />
      </div>
      <AuthButtons />
    </Wrapper>
  )
}

export default Index

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 calc((100% - 1240px) / 2);

  .logo {
    margin: auto 117px auto 0;
  }
`
