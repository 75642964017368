import styled from 'styled-components'
import Logo from '../../components/Logo'

function ComplainHeader({customerImage}: {customerImage?: string}) {
  return <Wrapper>{customerImage ? <img src={customerImage} alt="customerImage" /> : <Logo />}</Wrapper>
}

export default ComplainHeader

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.08);

  .logo {
    width: 130px;
    height: 26px;
    margin: 26px 0 5px;
  }

  img {
    width: 100%;
    height: 60px;
  }
`
