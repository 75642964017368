import styled from 'styled-components'

const Button = styled.button`
  width: 100%;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  transition: background-color 0.3s;

  padding: 20px;

  &:hover {
    opacity: 0.8;
  }
`

export default Button
