import React, {forwardRef} from 'react'
import Hint, {IHintProps} from './Hint'
import {IInputProps, Input} from './Input'
import styled from 'styled-components'
import {Label} from './Label'

export interface IFieldProps extends Partial<IInputProps & IHintProps> {
  label?: string
  error?: string
  className?: string
}

export const Field = forwardRef<HTMLInputElement, IFieldProps>(({label, className, error, id, name, ...rest}, ref) => (
  <Wrapper className={className}>
    {label && <Label htmlFor={id || name}>{label}</Label>}
    <Input className={'main_input'} id={id} name={name} ref={ref} {...rest} />
    <Hint error={error} />
  </Wrapper>
))

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
`
