import React from 'react'
import styled from 'styled-components'

function Footer() {
  return (
    <Wrapper>
      <FooterInfos>
        <FooterInfo href="/MakeBiz_Privacy.html" target="_blank">
          개인정보처리방침
        </FooterInfo>
        <FooterInfo href="/MakeBiz_Services.html" target="_blank">
          서비스이용약관
        </FooterInfo>
        <FooterInfoText>
          서비스 제안서 (
          <FooterInfo href="/assets/pdf/service_complaint.pdf" target="_blank">
            민원
          </FooterInfo>
          &nbsp;/&nbsp;
          <FooterInfo href="/assets/pdf/service_petrol.pdf" target="_blank">
            시설점검
          </FooterInfo>
          &nbsp;/&nbsp;
          <FooterInfo href="/assets/pdf/service_free.pdf" target="_blank">
            근태
          </FooterInfo>
          )
        </FooterInfoText>
        <FooterInfoText>
          웹 메뉴얼 (
          <FooterInfo href="/assets/pdf/web_complaint.pdf" target="_blank">
            민원+시설점검
          </FooterInfo>
          &nbsp;/&nbsp;
          <FooterInfo href="/assets/pdf/web_work.pdf" target="_blank">
            근태
          </FooterInfo>
          )
        </FooterInfoText>
        <FooterInfoText>
          모바일 메뉴얼 (
          <FooterInfo href="/assets/pdf/mobile_complaint.pdf" target="_blank">
            민원+시설점검
          </FooterInfo>
          &nbsp;/&nbsp;
          <FooterInfo href="/assets/pdf/mobile_work.pdf" target="_blank">
            근태
          </FooterInfo>
          )
        </FooterInfoText>
      </FooterInfos>
      <Hr />
      <Information>
        <CompanyInfos>
          <CompanyInfo>주식회사 메이크비즈 | 대표 : 홍현미 | 사업자 등록번호 : 872-81-02042</CompanyInfo>
          <CompanyInfo>주소 : 서울시 금천구 가산디지털1로 5, 1410호(가산동, 대륭테크노타운 20차)</CompanyInfo>
          <CompanyInfo>통신판매업신고 : 2022-서울금천-1757</CompanyInfo>
          <CompanyInfo>전화번호 : 070-4366-0555</CompanyInfo>
          <CompanyInfo>email : support@helpme365.kr</CompanyInfo>
        </CompanyInfos>
        <ButtonArea>
          <Remote href={'https://939.co.kr/makebiz '} target="_blank" rel="noreferrer">
            <p className={'title'}>원격 지원 요청</p>
            <p className={'content'}>
              <a className={'day'}>평일</a> 10:00 ~ 11:30
            </p>
            <p className={'time'}>13:30 ~ 17:00</p>
          </Remote>
          <AppDownload>
            <a
              href="https://apps.apple.com/kr/app/%EB%8F%84%EC%99%80%EC%A4%98365/id1666745903"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/app_ios.png" alt="앱스토어" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.helpme.prod&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/app_android.png" alt="플레이스토어" />
            </a>
          </AppDownload>
        </ButtonArea>
      </Information>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.footer`
  position: relative;

  background-color: ${(p) => p.theme.colors.footer};
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;

  padding: 40px calc((100% - 1080px) / 2) 64px;
`

const FooterInfos = styled.div`
  display: flex;
  gap: 16px;
`

const FooterInfo = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  cursor: pointer;
  color: white;

  &:hover {
    color: #e5e5e5;
  }
`
const FooterInfoText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  color: white;
`
const Hr = styled.hr`
  margin: 40px 0;
`

const CompanyInfos = styled.div`
  span:nth-child(2) {
    margin: 0 10px;
  }
`

const Information = styled.div`
  display: flex;
  justify-content: space-between;
`

const CompanyInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 13px;

  height: 89px;
  text-align: center;
`

const Remote = styled.a`
  border: 1px solid white;
  border-radius: 4px;

  padding: 15px 10px 0 10px;

  cursor: pointer;
  color: white;

  .title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .content {
    font-size: 12px;
  }

  .day {
    color: white;
    width: 22px;
  }

  .time {
    font-size: 12px;
    margin-left: 26px;
  }
`

const AppDownload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 120px;
    height: 36px;
  }
`
