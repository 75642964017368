import styled from 'styled-components'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import Icon from './Icon'
import {Label} from './fields/Label'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

interface SelectProps {
  name: string
  options?: any
  optionName: string
  placeholder: string
  label?: string
}

function Select({name, options, label, placeholder, optionName}: SelectProps) {
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState(placeholder)
  const {setFieldValue}: FormikContextType<FormikValues> = useFormikContext()

  const optionsRef = useRef<HTMLUListElement>(null)

  const handleClickSelectBox = useCallback(() => {
    setShow((prev) => !prev)
  }, [])

  const handleClickOption = useCallback(
    (option: any) => {
      setSelected(option['complainTypeItemName'] || option['name']) // 보여지는 text
      setShow(false)
      setFieldValue(name, option.id) // 실제 id 값
    },
    [show, selected]
  )

  const handleClickOptionsOutside = useCallback(
    (e: Event) => {
      if (!optionsRef.current) return
      if (show && !optionsRef.current.contains(e.target as Node)) {
        setShow(false)
      }
    },
    [show]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOptionsOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOptionsOutside)
    }
  })

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <SelectWrapper>
        <SelectBox onClick={handleClickSelectBox}>
          <p>{selected}</p>
          <Icon name={'down_arrow'} width={'12px'} height={'8px'} />
        </SelectBox>
        {show && (
          <OptionsBox ref={optionsRef}>
            {options?.map((option: any, index: number) => (
              <Option
                key={index}
                onClick={() => {
                  handleClickOption(option)
                }}
              >
                {option[`${optionName}`]}
              </Option>
            ))}
          </OptionsBox>
        )}
      </SelectWrapper>
    </Wrapper>
  )
}

export default Select

const Wrapper = styled.div`
  display: flex;

  flex-direction: column;

  margin-bottom: 24px;
  cursor: pointer;
`

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`

const SelectBox = styled.div`
  padding: 14px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OptionsBox = styled.ul`
  box-sizing: border-box;
  width: inherit;
  max-height: 155px;

  position: absolute;
  top: 45px;

  padding: 2px 0px;
  border: 1px solid rgba(51, 51, 51, 0.2);

  border-radius: 4px;
  background-color: white;
  z-index: 10;

  overflow: scroll;
`

const Option = styled.li`
  padding: 6px 14px;
`
