import React, {Dispatch, SetStateAction, useCallback, useState} from 'react'
import styled, {css} from 'styled-components'
import Icon from './Icon'

interface ImgPreviewProps {
  preview: string
  setPreview: Dispatch<SetStateAction<string>>
}

function ImgPreview({preview, setPreview}: ImgPreviewProps) {
  const [openModal, setOpenModal] = useState(false)

  const handleClickDeleteButton = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    setPreview('')
  }, [])

  const handleClickOpenModalButton = useCallback((e: any) => {
    e.stopPropagation()
    setOpenModal(true)
  }, [])

  const handleClickCloseButton = useCallback(() => {
    setOpenModal(false)
  }, [openModal])

  return (
    <>
      <PreviewImg onClick={handleClickOpenModalButton} src={preview}>
        <DeleteButton onClick={handleClickDeleteButton} type={'button'}>
          <Icon name={'delete_button'} width={'20px'} height={'20px'} aria-hidden="true" />
        </DeleteButton>
      </PreviewImg>
      {openModal && (
        <ModalWrapper>
          <CloseButton onClick={handleClickCloseButton} name={'close'} width={'18px'} height={'18px'} />
          <img className={'preview'} src={preview} alt={'preview'} />
        </ModalWrapper>
      )}
    </>
  )
}

export default ImgPreview

const DeleteButton = styled.button`
  background-color: transparent;

  width: 20px;
  height: 20px;
`

const PreviewImg = styled.div(
  ({src}: {src: string}) => css`
    width: 72px;
    height: 72px;

    background-image: url('${src}');
    background-size: cover;
    background-color: lightgray;

    display: flex;
    justify-content: flex-end;

    border-radius: 4px;
  `
)

const ModalWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;

  top: 0;
  right: 0;
  left: 0;

  z-index: 15;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .preview {
    width: 100%;

    max-height: 400px;

    bject-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CloseButton = styled(Icon)`
  position: fixed;
  top: 33px;
  right: 10px;
`
