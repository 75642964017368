import React from 'react'
import styled from 'styled-components'

export interface IHintProps {
  error?: string
}

function Hint({error}: IHintProps) {
  return <Wrapper>{error && <span>{error}</span>}</Wrapper>
}

export default Hint

const Wrapper = styled.div`
  color: #f31515;
  position: absolute;
  bottom: -17px;

  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
`
