export const queryKeys = {
  banners: 'banners',
  majorCustomer: 'majorCustomer',
  notices: 'notices',
  notice: 'notice',
  auth: 'auth',
  users: 'users',
  complainResult: 'complainResult',
  selects: 'selects',
  complainPlaceCheck: 'complainPlaceCheck',
  complains: 'complains',
  complainsWriter: 'complainsWriter',
  userStatuses: 'userStatuses'
}
