import styled from 'styled-components'
import {Label} from './Label'
import React, {useState} from 'react'
import ImgButton from '../buttons/ImgButton'
import ImgPreview from '../ImgPreview'

interface ImgFieldProps {
  label?: string | React.ReactNode
  name: string
}

function ImgField({label, name}: ImgFieldProps) {
  const [preview, setPreview] = useState<string>('')

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <div style={{display: 'flex', gap: '12px'}}>
        <ImgButton name={name} count={preview ? 1 : 0} setPreview={setPreview} />
        {preview && <ImgPreview preview={preview} setPreview={setPreview} />}
      </div>
    </Wrapper>
  )
}

export default ImgField

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
