import Title from '../Title'
import {Form, Formik} from 'formik'
import CompanyInfoForm from './CompanyInfoForm'
import ManagerInfoForm from './ManagerInfoForm'
import React from 'react'
import * as yup from 'yup'
import SubmitButton from '../buttons/SubmitButton'
import {Hr} from '../Hr'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import {postUsers} from '../../api/users'
import {useRecoilValue} from 'recoil'
import {verificationState} from '../../stores/verification'
import {FormikHelpers} from 'formik/dist/types'
import {uploadFile} from '@weplanet-web/core'
import {usePage} from '../../hooks/usePage'

interface IInitialFormValues extends Omit<IProfile, 'userId' | 'businessLicense'> {
  code: string
  businessLicense?: FileList
  phoneToken: string
}

const initialFormValues: IInitialFormValues = {
  companyName: '',
  ceoName: '',
  businessNo: '',
  businessType: '',
  businessCategory: '',
  taxBillEmail: '',
  businessLicense: undefined,
  managerName: '',
  managerEmail: '',
  managerPhone: '',
  code: '',
  phoneToken: '',
  address: '',
  detailAddress: ''
}

const validationSchema = () =>
  yup.object().shape({
    companyName: yup.string().required('*회사명을 입력해주세요'),
    ceoName: yup.string().required('*대표자명을 입력해주세요'),
    businessNo: yup.string().required('*사업자번호를 입력해주세요'),
    businessLicense: yup.string().required('*사업자 등록증을 업로드해주세요.'),
    businessType: yup.string().required('*업태를 입력해주세요.'),
    businessCategory: yup.string().required('*종목을 입력해주세요.'),
    address: yup.string().required('*주소를 입력해주세요.'),
    detailAddress: yup.string().required('*상세주소를 입력해주세요.'),
    taxBillEmail: yup
      .string()
      .required('*세금계산서용 이메일 주소를 입력해주세요.')
      .email('*유효한 이메일 주소를 입력해주세요'),
    managerName: yup.string().required('*담당자명을 입력해주세요.'),
    managerEmail: yup
      .string()
      .required('*담당자 이메일 주소를 입력해주세요.')
      .email('*유효한 이메일 주소를 입력해주세요'),
    managerPhone: yup.string().required('*담당자 휴대폰 번호를 입력해주세요.'),
    code: yup.string().required('*휴대폰으로 받은 인증코드를 입력해주세요.')
  })

// TODO phoneToken

function RegisterProfileForm() {
  const {handleToDashboard} = usePage()
  const {phoneToken} = useRecoilValue(verificationState)

  const handleSubmit = async (values: IInitialFormValues, {setSubmitting}: FormikHelpers<IInitialFormValues>) => {
    const {code, businessLicense, ...rest} = values
    if (!businessLicense) return
    try {
      const fileType = businessLicense[0]?.type === 'application/pdf' ? 'file' : 'image'
      const url = await uploadFile({file: businessLicense[0], type: fileType, kind: 'users'})
      await postUsers({...rest, businessLicense: url || '', phoneToken})
      handleToDashboard()
    } catch (err: any) {
      toast.error(err.data.message)
    }
    setSubmitting(false)
  }

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(form) => (
        <StyledForm>
          <Title>회사 정보</Title>
          <CompanyInfoForm />
          <Hr style={{margin: '40px 0px'}} />
          <Title>담당자 정보</Title>
          <ManagerInfoForm />
          <SubmitButton title={'회사 정보 입력 완료'} form={form} />
        </StyledForm>
      )}
    </Formik>
  )
}

export default RegisterProfileForm

const StyledForm = styled(Form)`
  .narrow_field {
    margin-bottom: 24px;
  }
`
