import styled from 'styled-components'
import MembershipCard from '../../components/enroll/MembershipCard'

function Membership() {
  const FREE = 'FREE'
  const PRO = 'PRO'
  const ENTERPRISE = 'ENTERPRISE'
  return (
    <Wrapper>
      <CardSection>
        <MembershipCard membership={FREE} />
        <MembershipCard membership={PRO} />
        <MembershipCard membership={ENTERPRISE} />
      </CardSection>
      <MembershipMessage>* FREE 상품은 가입자당 1개만 신청할 수 있습니다.</MembershipMessage>
    </Wrapper>
  )
}

export default Membership

const Wrapper = styled.section`
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`

const CardSection = styled.section`
  display: flex;
  flex-direction: row;
  gap: 56px;
`

const MembershipMessage = styled.p`
  color: #f36536;
  font-weight: 700;
`
