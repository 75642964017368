import React, {PropsWithChildren} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

export interface INoticeProps {
  title: string
  info?: string
  children?: PropsWithChildren<any>
  more?: string
  className: string
}

function NoticeLayout({title, info, children, more, className}: INoticeProps) {
  return (
    <NoticeTitleDiv className={className}>
      <NoticeTitle>{title}</NoticeTitle>
      <NoticeInfo>{info}</NoticeInfo>
      <NoticeMore to="/notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=notice">{more}</NoticeMore>
      <NoticeContentDiv>{children}</NoticeContentDiv>
    </NoticeTitleDiv>
  )
}

export default NoticeLayout

const NoticeTitleDiv = styled.div`
  justify-items: center;
  padding-bottom: 72px;
`

const NoticeContentDiv = styled.div`
  margin: auto;
  max-width: 1080px;
`

const NoticeTitle = styled.p`
  font-weight: 600;
  font-size: 28px;
  vertical-align: middle;
  line-height: 33.61px;
  text-align: center;
  margin-bottom: 16px;
  padding-top: 72px;
`

const NoticeInfo = styled.p`
  line-height: 24px;
  font-size: 14px;
  color: #666666;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 40px;
`

const NoticeMore = styled(Link)`
  color: #cfcfcf;
  position: relative;
  left: 90%;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  margin: 0;
`
