import React from 'react'
import styled from 'styled-components'
import {ComplainTableDataType} from '../../pages/complain/Incomplete'

function MobileTableBody({rows}: {rows: Array<ComplainTableDataType>}) {
  return (
    <Tbody>
      {rows &&
        rows.map((item: any) => (
          <Tr key={item.title}>
            {item.title ? <Th className="title">{item.title}</Th> : null}
            {item.content ? <Td>{item.content}</Td> : null}
          </Tr>
        ))}
    </Tbody>
  )
}

export default MobileTableBody

const Tbody = styled.tbody`
  color: #333333;
  font-size: 13px;
  font-weight: 400;
`

const Tr = styled.tr`
  margin: 16px 0;
  border-top: 1px solid #d3d3d3;
  line-height: 20px;
`

const Th = styled.th`
  min-width: 75px;
  padding: 12px 10px 12px 12px;
  text-align: center;
  vertical-align: middle;
  background-color: #f9fafa;
`

const Td = styled.td`
  padding: 9px 0 9px 9px;
  text-align: left;
  vertical-align: middle;
`

const StyledImg = styled.img`
  max-width: 106px;
  max-height: 100px;
`
