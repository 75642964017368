import ComplaintRequest from '../pages/complain/ComplaintRequest'
import Complain from '../pages/complain/Complain'
import Incomplete from '../pages/complain/Incomplete'
import Complete from '../pages/complain/Complete'

const complaintRoutes = [
  {index: true, element: <Complain />},
  {path: 'incomplete', element: <Incomplete />},
  {path: 'complete', element: <Complete />},
  {path: 'complaint-request', element: <ComplaintRequest />}
]

export default complaintRoutes
