import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import useGetNoticesQuery from '../../hooks/useNotices/useGetNoticesQuery'
import {dateFormat} from '../../lib/dateFormat'
import CardForm from './CardForm'

function NoticeCard() {
  const navigate = useNavigate()

  const {data: notices} = useGetNoticesQuery({
    start: 0,
    perPage: 3,
    noticeType: 'notice',
    sort: 'createdAt',
    order: 'DESC'
  })

  function Notice({notice}: {notice?: INotices}) {
    const noticeGrid =
      notice &&
      notice.data &&
      notice.data.map(
        (item: INotice) =>
          item.noticeType === 'notice' && (
            <StyledDiv key={item.id} onClick={() => navigate(`/notices/detail?id=${item.id}`)}>
              <NoticeTitle>{item.title}</NoticeTitle>
              <Date>
                {dateFormat(item.createdAt)
                  .split('')
                  .map((v) => (
                    <span>{v}</span>
                  ))}
              </Date>
            </StyledDiv>
          )
      )
    return <>{noticeGrid}</>
  }

  return (
    <CardForm title={'공지사항'} more={'/notices/all?order=DESC&sort=createdAt&start=0&perPage=10&noticeType=notice'}>
      <NoticeArea>
        <Notice notice={notices} />
      </NoticeArea>
    </CardForm>
  )
}

export default NoticeCard

const NoticeArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const NoticeTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 0;
`

const Date = styled.span`
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  width: 90px;

  text-align: right;
`

const NoticeContents = styled.span`
  color: #363636;
  font-size: 15px;
  line-height: 27px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`
