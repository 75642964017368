import FormikField from '../fields/FormikField'
import InputButton from '../buttons/InputButton'
import VerificationsType from '../../@types/enums/VerificationsType'
import VerificationField from '../fields/VerificationField'
import styled from 'styled-components'
import {useCallback, useState} from 'react'
import useVerification from '../../hooks/useVerification'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import {useRecoilValue} from 'recoil'
import {verificationState} from '../../stores/verification'
import Checkbox from '../Checkbox'

interface VerificationFormProps {
  placeManageId?: any
}

function VerificationForm({placeManageId}: VerificationFormProps) {
  const {showVerificationField} = useRecoilValue(verificationState)
  const {handleVerify} = useVerification()
  const [privacy, setPrivacy] = useState(false)

  const {values}: FormikContextType<FormikValues> = useFormikContext()

  const handleClickVerifyButton = useCallback(async () => {
    await handleVerify(values['notificationPhone'], VerificationsType.RECEIPT_COMPLAIN, placeManageId)
  }, [placeManageId, values['notificationPhone']])

  return (
    <ResultsNotificationWrapper>
      <Checkbox name="privacy" consent="essential" onChange={() => setPrivacy(!privacy)}>
        <a href="/MakeBiz_Privacy.html" target="_blank">
          개인정보 처리방침 동의
        </a>
      </Checkbox>
      {privacy && (
        <FormikField
          className={'field'}
          placeholder={'연락처를 입력해주세요.'}
          label={'연락처'}
          name={'notificationPhone'}
          endAdornment={
            <InputButton title={'전송'} disabled={!values.notificationPhone} onClick={handleClickVerifyButton} />
          }
        />
      )}
      {showVerificationField && (
        <VerificationField className={'field'} label={'인증번호'} name={'code'} buttonName={'인증'} />
      )}
    </ResultsNotificationWrapper>
  )
}

export default VerificationForm

const ResultsNotificationWrapper = styled.div`
  .field {
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);

    label {
      font-size: 13px;
      line-height: 26px;

      text-transform: capitalize;

      color: rgba(0, 0, 0, 0.6);
    }

    .input_box {
      border: none;
      padding: 10px 0px;
    }
  }
`
