import FormikField, {IFormikFieldProps} from './FormikField'
import InputButton from '../buttons/InputButton'
import styled from 'styled-components'
import {useEffect, useState} from 'react'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

import {useRecoilValue} from 'recoil'
import {timeFormat} from '../../lib/timeFormat'
import {verificationState} from '../../stores/verification'
import useVerification from '../../hooks/useVerification'

interface IVerificationFieldProps extends IFormikFieldProps {
  buttonName: string
}

function VerificationField({name, buttonName, onClick, disabled, ...rest}: IVerificationFieldProps) {
  const [time, setTime] = useState(299)
  const {errors, values, setFieldValue}: FormikContextType<FormikValues> = useFormikContext()
  const verify = useRecoilValue(verificationState)

  const {handleCodeVerify} = useVerification()

  useEffect(() => {
    if (time > 0 && verify.expireAt && verify.verify) {
      const counter = setInterval(() => {
        const gap = Math.floor((new Date(verify.expireAt).getTime() - new Date().getTime()) / 1000)
        if (gap < 0) {
          setTime(0)
        } else {
          setTime(gap)
        }
      }, 1000)
      return () => clearInterval(counter)
    }
  }, [verify, time])

  useEffect(() => {
    if (localStorage.getItem('code')) {
      setFieldValue(name, localStorage.getItem('code'))
    }
  }, [verify])

  return (
    <FormikField
      value={values[`${name}`] || ''}
      name={name}
      placeholder={'인증번호를 입력해주세요.'}
      endAdornment={
        <EndAdornmentWrapper>
          <span className="timer">{verify.expireAt && verify.verify && timeFormat(time)}</span>
          <InputButton
            title={buttonName}
            disabled={Boolean(errors[`${name}`])}
            onClick={async () => {
              await handleCodeVerify(values[`${name}`])
              setTime(299)
            }}
          />
        </EndAdornmentWrapper>
      }
      {...rest}
    />
  )
}

export default VerificationField

const EndAdornmentWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  .timer {
    font-weight: 500;
    font-size: 14px;

    color: #f31515;
  }
`
