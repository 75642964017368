import {request} from '@weplanet-web/core'
import {IUserInfo} from '../../stores/userInfo'

const PATH = '/auth'

function postAuth(data: IPostAuth) {
  return request({url: PATH, method: 'POST', data: data})
}

function postAuthRegister(data: IPostAuthRegister) {
  return request({url: PATH + '/register', method: 'POST', data: data})
}

function getAuthAccountId(accountId: string) {
  return request({url: PATH + '/accountId', method: 'GET', params: {accountId}})
}

function getAuth(): Promise<IUserInfo> {
  return request({url: PATH, method: 'GET'})
}

function postAuthResetPassword(data: IGetAuthResetPassword) {
  return request({url: PATH + '/resetPassword', method: 'POST', data: data})
}

function deleteAuth() {
  return request({url: PATH, method: 'DELETE'})
}

export {postAuth, getAuthAccountId, postAuthRegister, getAuth, postAuthResetPassword, deleteAuth}
