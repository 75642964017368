import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

interface IPayments {
  color: string
  explainPayment: string
  cost: string
  advantages: string[]
  pay: string
}

function MembershipCard({membership}: {membership: string}) {
  const [payments, setPayments] = useState<IPayments>({
    color: '#19AE54',
    explainPayment: '소호 사업자를 위한 나눔 서비스',
    cost: '월 0원 / 10시설(장소)',
    advantages: [
      '비실명 민원 등록 및 결과 조회',
      '민원 관리',
      'VOC 리포트',
      'QR코드 안내문 인쇄',
      '사업장관리자 웹(계정 1개)',
      '현장담당자 어플(계정 10개)'
    ],
    pay: '무료체험'
  })

  function handlePaymentsInfos(membership: string) {
    switch (membership) {
      case 'FREE':
        return setPayments({
          color: '#19AE54',
          explainPayment: '소호 사업자를 위한 나눔 서비스',
          cost: '월 0원 / 10시설(장소)',
          advantages: [
            '비실명 민원 등록 및 결과 조회',
            '민원 관리',
            'VOC 리포트',
            'QR코드 안내문 인쇄',
            '사업장관리자 웹(계정 1개)',
            '현장담당자 어플(계정 10개)'
          ],
          pay: '무료체험'
        })
      case 'PRO':
        return setPayments({
          color: '#1072BD',
          explainPayment: '법인 사업자를 위한 가성비 서비스',
          cost: '월 5,500원 / 25시설(장소)',
          advantages: [
            'FREE의 모든 기능 +',
            '실명 민원 등록 및 결과 조회',
            '시설점검 관리',
            '회사/사업장 공지사항',
            '사업장관리자 웹(계정 100개)',
            '현장담당자 어플(계정 200개)'
          ],
          pay: '30일 무료체험'
        })
      case 'ENTERPRISE':
        return setPayments({
          color: '#F1663F',
          explainPayment: '고객 맞춤형 서비스',
          cost: '별도 문의',
          advantages: [
            'PRO의 모든 기능 +',
            '맞춤형 데시보드',
            '로컬 서버 구축',
            '주문형 QR코드(크기 및 재질)',
            '사업장관리자 웹(계정 무제한)',
            '현장담당자 어플(계정 무제한)'
          ],
          pay: '문의하기'
        })
      default:
        return setPayments({
          color: '#19AE54',
          explainPayment: '소호 사업자를 위한 나눔 서비스',
          cost: '월 0원 / 10시설(장소)',
          advantages: [
            '비실명 민원 등록 및 결과 조회',
            '민원 관리',
            'VOC 리포트',
            'QR코드 안내문 인쇄',
            '사업장관리자 웹(계정 1개)',
            '현장담당자 어플(계정 10개)'
          ],
          pay: '무료체험'
        })
    }
  }

  useEffect(() => {
    handlePaymentsInfos(membership)
  }, [membership])

  return (
    <Wrapper>
      <MembershipArea color={payments.color}>
        <MembershipTitle>{membership}</MembershipTitle>
        <MembershipInfo>{payments.explainPayment}</MembershipInfo>
      </MembershipArea>
      <Fee color={payments.color}>
        <span className="MO">
          <u>{payments.cost}</u>
        </span>
      </Fee>
      <Benefits>
        {payments.advantages.map((advantages) => {
          return <Benefit>{advantages}</Benefit>
        })}
      </Benefits>
      <BuyNow color={payments.color}>{payments.pay}</BuyNow>
    </Wrapper>
  )
}

export default MembershipCard

const Wrapper = styled.div`
  width: 329px;
  height: 563px;
  border-radius: 16px;
  box-shadow: 2px 8px 24px rgba(0, 0, 0, 0.2);
  background: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
`

const MembershipArea = styled.div<{color: string}>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  align-items: center;
  text-align: center;
  padding-top: 16px;
  width: 226px;
  height: 96px;
  border-radius: 0px 0px 20px 20px;
  color: white;
  background-color: ${(p) => p.color};
`

const MembershipTitle = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.19px;
  text-align: center;
`
const MembershipInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`
const Fee = styled.div<{color: string}>`
  font-size: 48px;
  font-weight: 500;
  color: ${(p) => p.color};

  .MO {
    font-weight: 300;
    font-size: 23px;
  }
`

const Benefits = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  gap: 16px;
`

const Benefit = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: flex-start;
  width: 100%;

  color: #999999;

  background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8382 2.56892C14.1872 2.19013 14.1873 1.57108 13.8385 1.19215C13.4852 0.802618 12.9006 0.802615 12.5472 1.19214C12.5471 1.19224 12.5471 1.19233 12.547 1.19242L5.60238 8.73181L2.45249 5.31214C2.09915 4.92853 1.5151 4.92853 1.16175 5.31214C0.812749 5.69104 0.812749 6.31033 1.16175 6.68923L4.957 10.8095C5.13234 10.9999 5.36627 11.1 5.60238 11.1C5.83848 11.1 6.07241 10.9999 6.24775 10.8095L13.8382 2.56892Z' fill='%23EB603E' stroke='white' stroke-width='0.2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: left;
  padding-left: 23px;
`
const BuyNow = styled.div<{color: string}>`
  font-weight: 500;
  font-size: 18px;
  line-height: 44px;
  width: 142px;
  height: 44px;
  border-radius: 48px;
  text-align: center;
  color: ${(p) => p.color};
  border: 1px solid ${(p) => p.color};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
