import {request} from '@weplanet-web/core'

const PATH = '/notices'

function getNotices(data: IGetNotices) {
  return request<INotices>({url: PATH, method: 'GET', params: data})
}

function getNotice({id}: {id: number}) {
  return request<INotice>({url: `${PATH}/${id}`, method: 'GET'})
}

export {getNotices, getNotice}
