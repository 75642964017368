import {createGlobalStyle} from 'styled-components'

const globalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  :root {
    font-family: 'Pretendard', sans-serif;
  }
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html, body {
    min-height: 100vh;
    margin: 0;
  }


  html,
  body,
  #root {
    height: 100%;
  }

  input {
    padding: 0;
    border: none;
    background: transparent;
    font-size: 1rem;

    ::placeholder {
      color: #dbdbdb;
    }
  }

  input:active,
  input:focus,
  input:focus-visible {
    border: none;
    outline: 0;
  }

  label {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

  }

  button {
    padding: 0px;
    border: none;

    font-size: 1rem;
    white-space: nowrap;

    color: white;
    background-color: #F36536;
    border-radius: 4px;

    cursor: pointer;

    &:disabled {
      background: #D7D5D5;
    }
  }

  a {
    text-decoration: none;

    color: #424242;

  }


  #root {
    margin: auto;
    min-width: 320px;

    display: flex;
    flex-direction: column;
  }
`

export default globalStyles
