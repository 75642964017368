import {request} from '@weplanet-web/core'

const PATH = '/complains'

function postComplains(data: IPostComplains) {
  return request<{id: number; selectId: string}>({url: PATH, method: 'POST', data: data})
}

function getComplainResult(placeManageId: number) {
  return request<IGetComplainResult>({
    url: `${PATH}/complainResult/${placeManageId}`,
    method: 'GET'
  })
}

function getComplainPlaceCheck(placeManageId: string | null) {
  return request<IGetComplainPlaceCheck>({
    url: `${PATH}/placeCheck/${placeManageId}`,
    method: 'GET'
  })
}
function getPointPlaceCheck(params: any) {
  return request<IGetComplainPlaceCheck>({
    url: `/attendance-points`,
    method: 'GET',
    params
  })
}

function getComplains(complainId: number) {
  return request<IGetComplains>({
    url: `${PATH}/${complainId}`,
    method: 'GET'
  })
}

function getComplainsWriter(complainId: string | null) {
  return request<IGetComplains>({
    url: `${PATH}/${complainId}/writer`,
    method: 'GET'
  })
}

export {postComplains, getComplainResult, getComplainPlaceCheck, getPointPlaceCheck, getComplains, getComplainsWriter}
