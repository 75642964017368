import React from 'react'
import styled from 'styled-components'
import useGetMajorCustomersQuery from '../../hooks/useMajorCustomers/useGetMajorCustomersQuery'

function MainCustomers() {
  const {data: majorCustomer} = useGetMajorCustomersQuery()
  const majorCustomers = majorCustomer?.data.map((customer: {businessImage: string}) => ({
    businessImage: customer.businessImage
  }))

  const customerImage =
    majorCustomers &&
    majorCustomers.map((image: {businessImage: string}) => (
      <Customer key={image.businessImage}>
        <img src={image.businessImage} alt="business" />
      </Customer>
    ))

  const businessArr = [
    'ajoumotor.png',
    'chonnam.png',
    'dcu.png',
    'dongguk.png',
    'fourseason.png',
    'golfzone.png',
    'hanwha.png',
    'kabnk.png',
    'hyundai.png',
    'kolon.png',
    'koreamedic.png',
    'kr.png',
    'lotte.png',
    'mk.png',
    'myoungju.png',
    'sk.png',
    'spc.png',
    'sunmoon.png',
    'yeungnam.png',
    'yonsei.png'
  ]
  const DummyImage = businessArr.map((arr) => (
    <Customer key="business">
      <img src={`/assets/images/${arr}`} alt="businessImg" />
    </Customer>
  ))

  return (
    <Wrapper>
      <Title>주요 고객사</Title>
      <Infos>도와줘 365는 다양한 기업 및 기관들과 함께하고 있습니다.</Infos>
      <CustomersArea>{DummyImage}</CustomersArea>
    </Wrapper>
  )
}

export default MainCustomers

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 64px 10%;
  text-align: center;
`

const Title = styled.div`
  font-weight: 600;
  height: 100%;
  line-height: 33.61px;
  font-size: 28px;
`

const Infos = styled.div`
  color: #666666;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
`

const CustomersArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 24px;
`

const Customer = styled.div`
  width: 20%;

  img {
    width: 80%;
    border: 1px solid #e2e2e2;
  }
`
