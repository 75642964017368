import {Label} from '../fields/Label'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Checkbox from '../Checkbox'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import {Hr} from '../Hr'

function TermsForm() {
  const [allCheck, setAllCheck] = useState(false)
  const {
    setFieldValue,
    values,
    values: {
      terms: {over14YearsOld, termsOfServiceConsent, privacyPolicyConsent, PersonalInfoCollectionConsent, marketing}
    }
  }: FormikContextType<FormikValues> = useFormikContext()

  const handleChangeAllAgree = (event: any) => {
    const checked = event.target.checked
    setAllCheck(!allCheck)
    setFieldValue('terms', {
      over14YearsOld: checked,
      termsOfServiceConsent: checked,
      privacyPolicyConsent: checked,
      PersonalInfoCollectionConsent: checked,
      marketing: checked
    })
  }

  useEffect(() => {
    if (
      !over14YearsOld ||
      !termsOfServiceConsent ||
      !privacyPolicyConsent ||
      !PersonalInfoCollectionConsent ||
      !marketing
    ) {
      setAllCheck(false)
    }

    if (over14YearsOld && termsOfServiceConsent && privacyPolicyConsent && PersonalInfoCollectionConsent && marketing) {
      setAllCheck(true)
    }
  }, [values])

  return (
    <Wrapper>
      <Label>약관동의</Label>
      <TermsBox>
        <Checkbox name="allCheck" checked={allCheck} onChange={handleChangeAllAgree}>
          <strong style={{fontWeight: '600'}}>전체동의</strong>
        </Checkbox>
        <Hr />
        <Checkbox name="terms.over14YearsOld" checked={over14YearsOld} consent="essential">
          만 14세 이상입니다.
        </Checkbox>
        <Checkbox name="terms.termsOfServiceConsent" checked={termsOfServiceConsent} consent="essential">
          서비스 이용약관 동의
        </Checkbox>
        <Checkbox name="terms.privacyPolicyConsent" checked={privacyPolicyConsent} consent="essential">
          개인정보처리방침 동의
        </Checkbox>
        <Checkbox
          name="terms.PersonalInfoCollectionConsent"
          checked={PersonalInfoCollectionConsent}
          consent="essential"
        >
          개인정보수집 및 이용동의
        </Checkbox>
        <Checkbox name="terms.marketing" checked={marketing} consent="optional">
          마케팅 활용 동의
        </Checkbox>
      </TermsBox>
    </Wrapper>
  )
}

export default TermsForm

const Wrapper = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

const TermsBox = styled.article`
  border: 1px solid #dbdbdb;
  padding: 24px;
  border-radius: 4px;
  div: nth-child(1) {
    margin-top: 0px;
  }
`
