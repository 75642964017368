import {request} from '@weplanet-web/core'

const PATH = '/users'

function getUsers() {
  return request<IUsers>({url: PATH, method: 'GET'})
}

function postUsers(data: IPostUsers) {
  return request({url: PATH, method: 'POST', data: data})
}

// function getUsersEmail(email: string) {
//   return request({url: PATH + '/findAccountId', method: 'POST'})
// }

export {getUsers, postUsers}
