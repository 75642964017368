import {QueryClient} from '@tanstack/react-query'

function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: false
      }
    }
  })
}

const queryClient = generateQueryClient()
export {queryClient}
