import {Form, Formik} from 'formik'
import {FormikHelpers} from 'formik/dist/types'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import * as yup from 'yup'
import {notificationType} from '../../stores/notificationType'
import {verificationState} from '../../stores/verification'
import Select from '../Select'
import Textarea from '../Textarea'
import {RoundButton} from '../buttons/RoundButton'
import ImgField from '../fields/ImgField'
import VerificationForm from './VerificationForm'

import {uploadFile} from '@weplanet-web/core'
import Resizer from 'react-image-file-resizer'
import {toast} from 'react-toastify'
import {postComplains} from '../../api/complains'

interface ComplaintFormProps {
  placeManageId: string | null
  complainTypeItems: IComplainTypeItem[]
}

interface IInitialFormValues {
  complainTypeItemId: number | null
  complainPicture?: FileList
  notificationPhone?: string
  complainEtc?: string
  notificationType: 'sms' | 'web' | undefined
}

const initialFormValues: IInitialFormValues = {
  complainTypeItemId: null,
  complainPicture: undefined,
  notificationPhone: '',
  complainEtc: undefined,
  notificationType: undefined
}

const validationSchema = () =>
  yup.object().shape({
    complainTypeItemId: yup.string().required('민원을 선택해주세요.'),
    notificationPhone: yup.string().when('notificationType', {
      is: 'sms',
      then: (schema) => schema.required('핸드폰 인증을 해주세요.')
    })
  })

function ComplaintForm({placeManageId, complainTypeItems}: ComplaintFormProps) {
  const {phoneToken} = useRecoilValue(verificationState)
  const navigate = useNavigate()
  const notificationTypes = useRecoilValue(notificationType)
  const [prams, _] = useSearchParams()

  window.addEventListener('pageshow', (e) => {
    if (e.persisted) {
      navigate(`/complain?placeManageId=${placeManageId}`)
    }
  })

  window.onpopstate = function (event) {
    if (event) {
      navigate(`/complain?placeManageId=${placeManageId}`)
    }
  }

  const noticeOptions = [
    (notificationTypes.notificationType === 'web' || notificationTypes.notificationType === 'all') && {
      id: 'web',
      name: '웹조회'
    },
    (notificationTypes.notificationType === 'sms' || notificationTypes.notificationType === 'all') && {
      id: 'sms',
      name: '알림톡(문자)'
    }
  ]

  const handleSubmit = async (values: IInitialFormValues, {setSubmitting}: FormikHelpers<IInitialFormValues>) => {
    const {complainTypeItemId, complainPicture, notificationPhone, complainEtc, notificationType} = values
    if (!values.notificationType) return
    const resizeImage = async (file: any, maxWidth: number, maxHeight: number, quality: number) => {
      return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          'JPEG',
          quality,
          0,
          (uri: any) => {
            const binaryString = atob(uri.split(',')[1])
            const len = binaryString.length
            const bytes = new Uint8Array(len)
            for (let i = 0; i < len; ++i) {
              bytes[i] = binaryString.charCodeAt(i)
            }
            const blob = new Blob([bytes], {type: 'image/jpeg'})
            const resizedFile = new File([blob], file.name, {type: file.type})
            resolve(resizedFile)
          },
          'base64'
        )
      })
    }

    let resizedImage: any = {}
    if (complainPicture && complainPicture.length > 0) {
      resizedImage = await resizeImage(complainPicture[0], 800, 600, 100)
    }

    try {
      const url = !!complainPicture
        ? await uploadFile({
            file: resizedImage,
            type: 'image',
            kind: 'users'
          })
        : undefined
      const complainId = await postComplains({
        complainTypeItemId: complainTypeItemId === 0 ? null : complainTypeItemId,
        placeManageId: notificationTypes.id,
        phoneToken: values.notificationType === 'sms' ? phoneToken : undefined,
        notificationType,
        notificationPhone: values.notificationType === 'sms' ? notificationPhone : undefined,
        ...(!!complainPicture && {complainPicture: url}),
        ...(!!complainEtc && {complainEtc})
      })
      if (complainId) {
        toast.success('민원 등록 완료')
        navigate(`/complain/incomplete?placeManageId=${placeManageId}&complainId=${complainId.selectId}`)
      } else {
        return
      }
    } catch (err: any) {
      toast.error('민원 요청에 실패했습니다.')
    }
    setSubmitting(false)
  }

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(form) => (
        <Form>
          <Select
            name={'complainTypeItemId'}
            options={complainTypeItems}
            optionName={'complainTypeItemName'}
            label={'민원선택'}
            placeholder={'민원을 선택해주세요.'}
          />
          {form.values['complainTypeItemId'] === 0 && <Textarea maxLength={100} name={'complainEtc'} />}
          <ImgField
            name={'complainPicture'}
            label={
              <>
                사진 등록 <span style={{color: 'red'}}>(선택)</span>
              </>
            }
          />
          <Select
            name={'notificationType'}
            options={noticeOptions}
            optionName={'name'}
            label={'처리결과 통보'}
            placeholder={'민원 처리결과 방법을 선택해주세요.'}
          />
          {form.values['notificationType'] === 'sms' ? (
            <VerificationForm placeManageId={prams.get('placeManageId')} />
          ) : null}
          <RoundButton
            type="submit"
            style={{marginTop: '53px'}}
            disabled={
              form.values['notificationType'] === 'sms'
                ? !(form.isValid && form.dirty) || form.isSubmitting
                : !(form.values['notificationType'] === 'web')
            }
          >
            민원 등록
          </RoundButton>
        </Form>
      )}
    </Formik>
  )
}

export default ComplaintForm
