import PasswordField from '../fields/PasswordField'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import SubmitButton from '../buttons/SubmitButton'
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

function ResetPasswordForm() {
  const navigate = useNavigate()
  const form: FormikContextType<FormikValues> = useFormikContext()

  useEffect(() => {
    if (form.values['accountId'] === '') {
      return navigate('/auth/find-password?step=accountVerification')
    }
  }, [form])

  return (
    <>
      <PasswordField name={'newPassword'} />
      <PasswordField name={'confirmNewPassword'} />
      <SubmitButton title="비밀번호 변경하기" form={form} />
    </>
  )
}

export default ResetPasswordForm
