import {Form, Formik} from 'formik'
import React from 'react'
import * as yup from 'yup'
import AccountVerificationForm from './AccountVerificationForm'
import {useNavigate} from 'react-router-dom'
import ResetPasswordForm from './ResetPasswordForm'
import {FormikHelpers} from 'formik/dist/types'
import {toast} from 'react-toastify'
import {useRecoilValue} from 'recoil'
import {verificationState} from '../../stores/verification'
import {postAuthResetPassword} from '../../api/auth'

interface IInitialFormValues {
  accountId: string
  phone: string
  code: string
  newPassword: string
  confirmNewPassword: string
}

const initialFormValues = {
  accountId: '',
  phone: '',
  code: '',
  newPassword: '',
  confirmNewPassword: ''
}

const validationSchema = (step: string) =>
  yup.object().shape({
    accountId: yup.string().required('*아이디를 입력해주세요'),
    phone: yup.string().required('*비밀번호를 입력해주세요'),
    code: yup.string().required('*인증번호를 입력해주세요'),
    ...(step === 'resetPassword' && {
      newPassword: yup.string().required('*새로운 비밀번호를 입력해주세요'),
      confirmNewPassword: yup
        .string()
        .required('*새로운 비밀번호를 다시한번 입력해주세요')
        .oneOf([yup.ref('newPassword'), null], '*비밀번호를 일치시켜 주세요.')
    })
  })

function FindPasswordForm({step}: {step: string}) {
  const navigate = useNavigate()
  const {phoneToken} = useRecoilValue(verificationState)

  const handleResetPassword = async (data: IGetAuthResetPassword) => {
    try {
      await postAuthResetPassword({...data})
      toast.success('비밀번호 변경에 성공했어요.')
      navigate('/auth/sign-in')
    } catch (err: any) {
      toast.error(err)
    }
  }

  const handleSubmit = async (values: IInitialFormValues, {setSubmitting}: FormikHelpers<IInitialFormValues>) => {
    const {accountId, phone, newPassword} = values
    if (phoneToken === '') return toast.error('핸드폰 번호 인증을 해주세요.')
    switch (step) {
      case 'accountVerification':
        navigate('/auth/find-password?step=resetPassword')
        break
      case 'resetPassword':
        await handleResetPassword({phone, phoneToken, accountId, password: newPassword})
        setSubmitting(false)
        break
    }
  }

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema(step)} onSubmit={handleSubmit}>
      {(form) => (
        <Form>
          {step === 'accountVerification' && <AccountVerificationForm />}
          {step === 'resetPassword' && <ResetPasswordForm />}
        </Form>
      )}
    </Formik>
  )
}

export default FindPasswordForm
