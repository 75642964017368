import React from 'react'
import styled from 'styled-components'
import {HeadCellType} from '../../pages/Notice/Notice'

function TableHead({headCells}: {headCells: Array<HeadCellType>}) {
  return (
    <Thead>
      <tr>
        {headCells &&
          headCells.map((item: any) => (
            <Th width={item.width} key={item.id}>
              {item.label}
            </Th>
          ))}
      </tr>
    </Thead>
  )
}

export default TableHead

const Thead = styled.thead`
  border-top: 2px solid #333333;
  border-bottom: 1px solid #333333;

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-align: center;
`

const Th = styled.th<{width: string}>`
  padding: 15px 0;
  width: ${(props) => props.width};
`
