import {useCallback} from 'react'
import {getAuthAccountId} from '../api/auth'
import {toast} from 'react-toastify'

function useAccountIdCheck() {
  const handleAccountIdCheck = useCallback(async (accountId: string) => {
    try {
      await getAuthAccountId(accountId)
      toast.success('사용가능한 아이디입니다.')
    } catch (err: any) {
      switch (err.status) {
        case 409: {
          toast.error('이미 사용중인 아이디입니다.')
          break
        }
        default: {
          toast.error('아이디 중복 확인에 실패했습니다. 잠시 후 다시 시도해주세요.')
        }
      }
    }
  }, [])
  return {handleAccountIdCheck}
}

export default useAccountIdCheck
