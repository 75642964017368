import React from 'react'
import styled from 'styled-components'
import ServicesTabButton from '../../components/buttons/ServicesTabButton'

function Foundation() {
  return (
    <Wrapper>
      <Title>적용예시</Title>
      {/* <ExplainArea>
        <span>QR코드</span>를 스캔하여 민원을 등록해보세요.
      </ExplainArea> */}
      <img src="/assets/images/foundation01.png" alt="적용예시01" />
      <img src="/assets/images/foundation02.png" alt="적용예시01" />
      <img src="/assets/images/foundation03.png" alt="적용예시01" />
      <img src="/assets/images/foundation04.png" alt="적용예시01" />
      <img src="/assets/images/foundation05.png" alt="적용예시01" />
    </Wrapper>
  )
}

export default Foundation

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 120px;
  gap: 32px;
`

const ExplainArea = styled.label`
  font-size: 24px;
  margin: 10px auto 30px auto;

  span {
    color: red;
  }
`

const ImageArea = styled.div`
  margin-bottom: 40px;
  border-bottom: 2px solid #f2f2f7;
  padding-bottom: 40px;

  width: 67.5%;
  max-width: 1080px;

  display: flex;
  flex-direction: row;
  gap: 40px;
`

const Img = styled.img`
  width: 48%;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin-top: 64px;
`
