import React, {forwardRef} from 'react'
import styled from 'styled-components'

export interface IInputProps extends React.ComponentPropsWithRef<'input'> {
  className?: string
  endAdornment?: string | JSX.Element | JSX.Element[]
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(({className, endAdornment, ...rest}, ref) => (
  <Box className="input_box">
    <input className={'main_input'} ref={ref} {...rest} />
    {endAdornment && <div>{endAdornment}</div>}
  </Box>
))

const Box = styled.div`
  border: 1px solid #dbdbdb;
  cursor: text;
  max-height: 100%;

  padding: 6px 6px 6px 16px;

  display: flex;
  border-radius: 4px;

  justify-content: space-between;
  align-items: center;

  .main_input {
    width: 100%;

    font-weight: 500;
    font-size: 16px;

    padding: 10px 0px;

    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
  }
`
