import {Form, Formik} from 'formik'
import PasswordField from '../fields/PasswordField'
import React, {useCallback} from 'react'
import Button from '../buttons/Button'
import FormikField from '../fields/FormikField'
import * as yup from 'yup'
import {postAuth} from '../../api/auth'
import {toast} from 'react-toastify'
import {FormikHelpers} from 'formik/dist/types'
import {usePage} from '../../hooks/usePage'

interface IInitialFormValues {
  accountId: string
  password: string
}

const initialFormValues = {
  accountId: '',
  password: ''
}

const validationSchema = () =>
  yup.object().shape({
    accountId: yup.string().required('*아이디를 입력해주세요'),
    password: yup.string().required('*비밀번호를 입력해주세요')
  })

function LoginForm() {
  const {handleToDashboard} = usePage()
  const handleSubmit = useCallback(async (values: IInitialFormValues, options?: FormikHelpers<IInitialFormValues>) => {
    try {
      await postAuth(values)
      handleToDashboard()
    } catch (err: any) {
      toast.error('로그인에 실패하였습니다.')
    } finally {
      options?.setSubmitting(false)
    }
  }, [])

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(form) => (
        <Form onSubmit={form.handleSubmit} style={{width: '100%'}}>
          <FormikField name="accountId" className="field" placeholder="아이디" />
          <PasswordField name="password" className="field" placeholder="비밀번호" />
          <Button type="submit" disabled={!(form.isValid && form.dirty) || form.isSubmitting}>
            로그인
          </Button>
          {window?.location.hostname === 'localhost' && (
            <Button
              type="button"
              style={{
                background: 'none',
                color: '#F36536',
                border: 'solid 1px #F36536',
                fontWeight: 'bold',
                marginTop: 16
              }}
              onClick={() =>
                handleSubmit({
                  accountId: process.env.REACT_APP_ACCOUNT_ID || '',
                  password: process.env.REACT_APP_PASSWORD || ''
                })
              }
            >
              🐶 🐾 📏
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
