import {Adsense} from '@ctrl/react-adsense'
import styled from 'styled-components'
import CustomerServices from '../components/notices/CustomerServices'
import Customers from '../components/notices/Customers'
import NoticeCard from '../components/notices/Notice'
import UserStatuses from '../components/notices/UserStatuses'
import useGetBannersQuery from '../hooks/useBanners/useGetBannersQuery'
import NoticeLayout from '../layouts/NoticeLayout'

function Main() {
  const {data: banners, isLoading, isSuccess} = useGetBannersQuery()

  return (
    <>
      {isSuccess && (
        <Banner>
          {banners?.data[0] ? (
            <img src={banners?.data[0].image || ''} alt="banner" />
          ) : (
            <img src="/assets/images/banner.jpg" width={800} alt="banner" />
          )}
          <Adsense client="ca-pub-9888358331463773" slot="5953469256" style={{width: 500, height: 300}} format="" />
        </Banner>
      )}
      <Contents>
        <UserStatuses />

        <NoticeLayout
          className="notice"
          title="주요 고객사"
          info="도와줘 365는 다양한 기업 및 기관들과 함께하고 있습니다."
        >
          <Customers />
        </NoticeLayout>

        <LayoutWrapper>
          <NoticeCard />
          <CustomerServices />
        </LayoutWrapper>

        <ImageWrapper>
          <Title>서비스 소개</Title>
          <img src="/assets/images/service01.png" alt="시설점검 이미지" />
          <img src="/assets/images/service02.png" alt="민원관리 이미지" />
          <img src="/assets/images/service03.jpg" alt="근태관리 이미지" />
        </ImageWrapper>
        {/* <NoticeLayout className="notice" title="서비스 소개">
          <ProductIntroduce />
        </NoticeLayout>
        <NoticeLayout className="notice" title="서비스 장점">
          <Services />
        </NoticeLayout>
        <NoticeLayout className="notice" title="시스템 구성도">
          <System />
        </NoticeLayout> */}
      </Contents>
    </>
  )
}

export default Main

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`

const Banner = styled.section`
  display: flex;
  justify-content: center;
  position: absolute;
  gap: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  margin-bottom: 64px;

  img {
    width: 800px;
    height: 300px;
  }
`

const Contents = styled.article`
  margin: 380px auto 0 auto;

  & > .notice:nth-child(even) {
    background-color: #f9f9f9;
  }
`
const LayoutWrapper = styled.ul`
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1080px;
  margin: 0 auto;
  padding: 85px 0 70px;
`
const Title = styled.h2`
  font-weight: 600;
  font-size: 28px;
  vertical-align: middle;
  line-height: 33.61px;
  text-align: center;
  margin-bottom: 16px;
  padding-top: 72px;
`
