import React from 'react'
import styled from 'styled-components'

function Support() {
  return (
    <Wrapper>
      <Title>고객지원</Title>
      <Content>
        <SupportArea>
          <SupportIndex>
            <p>고객지원 연락처</p>
            <p>운영시간</p>
            <p>문의메일</p>
          </SupportIndex>
          <SupportContent>
            <p style={{color: 'black', fontWeight: 'bold'}}>070-4366-0555</p>
            <p>평일 오전 10시 - 오후 05시</p>
            <p>support@helpme365.kr</p>
          </SupportContent>
        </SupportArea>
      </Content>
    </Wrapper>
  )
}

export default Support

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: 64px 260px;
`

const Content = styled.section`
  display: flex;
  justify-content: center;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;

  color: #333333;
`

const SupportArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
`

const SupportContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: left;
  font-size: 16px;
  color: #999999;
  font-weight: 500;
`

const SupportIndex = styled(SupportContent)`
  font-weight: 600;
  color: #333333;
`
