import {atom} from 'recoil'

interface IVerification {
  codeToken: string
  phoneToken: string
  expireAt: string
  code: string
  verify: boolean
  showVerificationField: boolean
}

export const verificationState = atom<IVerification>({
  key: 'verification',
  default: {
    codeToken: '',
    phoneToken: '',
    expireAt: '',
    code: '',
    verify: false,
    showVerificationField: false
  }
})
