import Notice from '../pages/Notice/Notice'
import Update from '../pages/Notice/Update'
import Detail from '../pages/Notice/Detail'

const noticesRoutes = [
  {
    index: true,
    path: 'all',
    element: <Notice />
  },
  {
    path: 'updates',
    element: <Update />
  },
  {
    path: 'detail',
    element: <Detail />
  }
]

export default noticesRoutes
