import {Field, IFieldProps} from './Field'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

export interface IFormikFieldProps extends IFieldProps {
  name: string
}

function FormikField({name, ...rest}: IFormikFieldProps) {
  const form: FormikContextType<FormikValues> = useFormikContext()

  return (
    <Field
      name={name}
      error={`${form.touched[name] && form.errors[name] ? form.errors[name] : ''}`}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      {...rest}
    />
  )
}

export default FormikField
