import {useNavigate, useSearchParams} from 'react-router-dom'
import useGetComplainTypeItemsQuery from '../../hooks/useSelects/useGetComplainTypeItemsQuery'
import ComplaintForm from '../../components/forms/ComplaintForm'
import {useEffect} from 'react'
import {toast} from 'react-toastify'

const direct = [{id: 0, complainTypeItemName: '직접입력'}]

function ComplaintRequest() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('placeManageId') || searchParams.get('selectId')
  const {data, isError} = useGetComplainTypeItemsQuery(id)

  useEffect(() => {
    if (isError) {
      toast.error('유효하지 않은 주소입니다.')
      // TODO : 404 페이지로
      navigate('/', {replace: true})
    }
  }, [isError])

  return (
    <>
      <ComplaintForm placeManageId={id} complainTypeItems={[...(data?.data ? data.data : []), ...direct]} />
    </>
  )
}

export default ComplaintRequest
