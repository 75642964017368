import {useRoutes} from 'react-router-dom'
import authRoutes from './auth'
import mainRoutes from './main'
import membershipRoutes from './membership'
import complaintRoutes from './complaint'

import AuthLayout from '../layouts/AuthLayout'
import EnrollLayout from '../layouts/EnrollLayout'
import Layout from '../layouts/Layout'
import ComplainLayout from '../layouts/ComplainLayout'
import servicesRoutes from './services'
import noticesRoutes from './notices'
import redirectRoutes from './redirect'

function Routes() {
  return useRoutes([
    {path: 'auth', element: <AuthLayout />, children: authRoutes},
    {
      path: 'membership',
      element: <EnrollLayout />,
      children: membershipRoutes
    },
    {path: '', element: <Layout />, children: mainRoutes},
    {path: 'services', element: <Layout />, children: servicesRoutes},
    {path: 'notices', element: <Layout />, children: noticesRoutes},
    {path: 'complain', element: <ComplainLayout />, children: complaintRoutes},
    {path: 'redirect-code', element: <ComplainLayout />, children: redirectRoutes}
  ])
}

export default Routes
