enum VerificationsType {
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
  CHANGE_PHONE = 'changePhone',
  FIND_ACCOUNT_ID = 'findAccountId',
  LOGIN_PHONE = 'loginPhone',
  RECEIPT_COMPLAIN = 'receiptComplain'
}

export default VerificationsType
