import styled from 'styled-components'

function Products() {
  return (
    <Wrapper>
      {/* <ServicesTabButton /> */}
      <Title>서비스 소개</Title>
      {/* <ProductIntroduce /> */}
      <img src="/assets/images/service01.png" alt="시설점검 이미지" />
      <img src="/assets/images/service02.png" alt="민원관리 이미지" />
      <img src="/assets/images/service03.jpg" alt="근태관리 이미지" />
      {/* <Title>서비스 장점</Title>
      <Services />
      <Title>시스템 구성도</Title>
      <System /> */}
    </Wrapper>
  )
}

export default Products

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
  margin-bottom: 120px;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin-top: 64px;
`
