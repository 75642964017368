import styled from 'styled-components'
import Button from './Button'

export interface IInputButtonProps {
  title: string
  disabled: boolean
  onClick: () => void
}

const InputButton = ({title, disabled, onClick, ...rest}: IInputButtonProps) => {
  return (
    <StyledButton type="button" disabled={disabled} onClick={onClick} {...rest}>
      {title}
    </StyledButton>
  )
}

export default InputButton
const StyledButton = styled(Button)`
  width: 77px;
  height: 36px;
  padding: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`
