import React from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

function TableBody({rows}: any) {
  const navigate = useNavigate()

  const handleToDetail = (id: number) => {
    navigate(`/notices/detail?id=${id}`)
  }

  return (
    <Tbody>
      {rows &&
        rows.map((item: any) => (
          <>
            <Tr key={item.id}>
              <Td>{item.id}</Td>
              <td className="title" onClick={(e) => handleToDetail(item.id)}>
                {item.title}
              </td>
              <td>{item.nickname}</td>
              <td>{item.createdAt}</td>
            </Tr>
          </>
        ))}
    </Tbody>
  )
}

export default TableBody

const Tbody = styled.tbody`
  color: #333333;

  .title {
    text-align: left;
    padding: 5px;
  }
`

const Tr = styled.tr`
  margin: 16px 0;
  text-align: center;
  border-bottom: 1px solid #d3d3d3;
  cursor: pointer;
`

const Td = styled.td`
  padding: 16px;
`
