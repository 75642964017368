import React, {useState} from 'react'
import styled from 'styled-components'
import qs from 'query-string'
import useGetNoticeQuery from '../../hooks/useNotices/useGetNoticeQuery'
import {dateFormat} from '../../lib/dateFormat'
import ImageModal from '../../components/ImageModal'

function Detail() {
  const apiParams: {id: number} & any = qs.parse(location.search)
  const {data: notice} = useGetNoticeQuery(apiParams)
  const [modalOpen, setModalOpen] = useState(false)

  const handleImageClick = () => {
    setModalOpen(true)
  }

  return (
    <Wrapper>
      <TitleArea>{notice?.noticeType === 'notice' ? '공지사항' : '업데이트 내역'}</TitleArea>
      <TableArea>
        <Tr>
          <TableIndex>제목</TableIndex>
          <Td>{notice?.title}</Td>
        </Tr>
        <Tr>
          <TableIndex>작성자</TableIndex>
          <Td>{notice?.administrator?.nickname}</Td>
        </Tr>
        <Tr>
          <TableIndex>작성일</TableIndex>
          <Td>{dateFormat(notice ? notice.createdAt : '-')}</Td>
        </Tr>
      </TableArea>
      <ContentDiv>
        <pre>{notice?.content}</pre>
        <div>
          {notice?.image && (
            <img src={notice?.image} alt={'컨텐츠 이미지'} width={300} height={300} onClick={handleImageClick} />
          )}
        </div>
      </ContentDiv>
      <ImageModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ImageArea>
          <img src={notice?.image} alt={'공지사항 이미지'} onClick={() => setModalOpen(false)} />
        </ImageArea>
      </ImageModal>
    </Wrapper>
  )
}

export default Detail

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 260px 64px 260px;
`

const TitleArea = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;

  color: #333333;
`

const TableArea = styled.table`
  width: 100%;
  border-top: 2px solid #333333;
  line-height: 24px;
`

const Tr = styled.tr`
  height: 24px;

  border-bottom: 1px solid #ededed;
`

const TableIndex = styled.td`
  background-color: rgba(230, 99, 56, 0.15);

  font-size: 14px;
  font-weight: 600;
  padding: 16px 25px;
  width: 100px;
  color: black;
`

const Td = styled.td`
  padding-left: 31px;
`

const ContentDiv = styled.div`
  justify-content: left;
  min-height: 334px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  img {
    cursor: pointer;

    :hover {
      filter: brightness(70%);
      transition: 0.3s;
    }
  }
`

const ImageArea = styled.div`
  text-align: center;
  overflow: scroll;

  img {
    max-width: 80%;
    margin: 20px 0;
  }
`
