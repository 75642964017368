import {request} from '@weplanet-web/core'

const PATH = '/verifications'

function postVerifications(data: IPostVerifications): Promise<IVerification> {
  return request({url: PATH, method: 'POST', data: data})
}

function postVerificationsConfirm(data: IPostVerificationsConfirm): Promise<{codeToken: string}> {
  return request({url: PATH + '/confirm', method: 'POST', data: data})
}

export {postVerifications, postVerificationsConfirm}
