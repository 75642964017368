import React from 'react'
import styled from 'styled-components'
import MobileTableBody from './MobileTableBody'
import {ComplainTableDataType} from '../../pages/complain/Incomplete'

function Table({rows}: {rows: Array<ComplainTableDataType>}) {
  return (
    <TableArea>
      <MobileTableBody rows={rows} />
    </TableArea>
  )
}

export default Table

const TableArea = styled.table`
  width: 100%;

  border-bottom: 1px solid #d3d3d3;
`
