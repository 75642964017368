import {Field as FieldComponent} from './Field'
import React, {useRef} from 'react'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'
import {IFormikFieldProps} from './FormikField'
import styled from 'styled-components'
import InputButton from '../buttons/InputButton'
import {toast} from 'react-toastify'

function FileField({name, placeholder, ...rest}: IFormikFieldProps) {
  const form: FormikContextType<FormikValues> = useFormikContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChangeUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files
    if (!file || !file[0]) return
    try {
      form.setFieldValue(name, file)
    } catch (err) {
      toast.error('잠시후 다시 시도해주세요.')
    }
  }

  return (
    <Field
      name={name}
      ref={inputRef}
      type={'file'}
      onBlur={form.handleBlur}
      placeholder={form.values?.businessLicense ? form.values.businessLicense[0]?.name : placeholder}
      accept=".pdf, .jpeg, .png"
      endAdornment={
        <InputButton
          title={'업로드'}
          disabled={false}
          onClick={() => {
            if (inputRef.current) inputRef.current.click()
          }}
        />
      }
      onChange={handleChangeUploadFile}
      {...rest}
    />
  )
}

export default FileField

const Field = styled(FieldComponent)`
  input {
    white-space: nowrap;
    font-weight: 500;

    &:before {
      content: '${(props) => props.placeholder}';

      color: #bdbdbd;
      width: 100%;
      display: inline-block;
    }

    ::file-selector-button {
      display: none;
    }
  }
`
