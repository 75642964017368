import React from 'react'
import Logo from '../../components/Logo'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import LoginForm from '../../components/forms/LoginForm'
import {Adsense} from '@ctrl/react-adsense'

function SignIn() {
  return (
    <Wrapper>
      <LoginWrapper>
        <Logo />
        <LoginForm />
        <FindWrapper>
          <Link to={'/auth/find-password?step=accountVerification'}>비밀번호 찾기</Link>
          <Link to={'/auth/sign-up'}>회원가입</Link>
        </FindWrapper>
      </LoginWrapper>
      <AdsenseWrapper>
        <Adsense client="ca-pub-9888358331463773" slot="5953469256" style={{width: 500, height: 300}} format="" />
      </AdsenseWrapper>
    </Wrapper>
  )
}

export default SignIn

const Wrapper = styled.section`
  display: flex;
  gap: 150px;
`

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;

  .logo {
    margin-bottom: 40px;
  }
`

const FindWrapper = styled.p`
  display: flex;
  padding: 0;
  margin-top: 24px;
  gap: 24px;
`

const AdsenseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 430px;
`
